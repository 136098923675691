import { FunctionComponent } from "react";
// import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import userAuth from "./util/auth-util";
// import userAuth from "./util/auth-util";

/** A higher-order component with conditional routing logic */
export function withCondition(
  Component: FunctionComponent,
  redirectTo: string
) {
  return function InnerComponent(props: any) {
    const isAuthenticated = userAuth.isAuthorized();
    // const { user } = useSelector((state: any) => state.userAuth);

    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Navigate to={redirectTo} replace />
    );
  };
}

export const checkLoggedIn = (Component: React.FunctionComponent) =>
  withCondition(Component, "/unauthorized");
