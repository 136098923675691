export const transformData = (data: any) => {
  // const formatData = (intervalData: any) =>
  //   intervalData.map((entry: any, index: any) => ({
  //     name: (index + 1).toString(),
  //     allUsers: entry.total_posts_count,
  //     tempUsers: entry.posts_with_votes_count,
  //     registeredUsers: entry.comments_count,
  //   }));

  const formatSimpleData = (intervalData: any, key: any) =>
    intervalData.map((entry: any, index: any) => ({
      name: (index + 1).toString(),
      value: entry[key],
    }));

  const formatSimplePost = (intervalData: any, key1: any, key2: any) =>
    intervalData.map((entry: any, index: any) => ({
      name: (index + 1).toString(),
      post: entry[key1],
      post_votes: entry[key2],
    }));

  return {
    // // weekly: formatData(data.weekly),
    // // monthly: formatData(data.monthly),
    // // daily: formatData(data.daily),
    // users: {
    //     weekly: formatSimpleData(data.weekly, "total_posts_count"),
    //     monthly: formatSimpleData(data.monthly, "total_posts_count"),
    //     daily: formatSimpleData(data.daily, "total_posts_count"),
    //   },
    posts: {
      weekly: formatSimplePost(
        data.weekly,
        "total_posts_count",
        "posts_with_votes_count"
      ),
      monthly: formatSimplePost(
        data.monthly,
        "total_posts_count",
        "posts_with_votes_count"
      ),
      daily: formatSimplePost(
        data.daily,
        "total_posts_count",
        "posts_with_votes_count"
      ),
    },
    comments: {
      weekly: formatSimpleData(data.weekly, "comments_count"),
      monthly: formatSimpleData(data.monthly, "comments_count"),
      daily: formatSimpleData(data.daily, "comments_count"),
    },
    votes: {
      weekly: formatSimpleData(data.weekly, "votes_count"),
      monthly: formatSimpleData(data.monthly, "votes_count"),
      daily: formatSimpleData(data.daily, "votes_count"),
    },
  };
};
