import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { colors } from "../../common/colors";

interface OptionsModalProps {
  open: boolean;
  onClose: () => void;
  options: any[];
  onSelect: (option: string) => void;
}

const OptionsModal: React.FC<OptionsModalProps> = ({
  open,
  onClose,
  onSelect,
  options,
}) => {
  const handleOptionSelect = (option: string) => {
    onSelect(option);
    onClose();
  };

  return (
    <Modal
      style={{ zIndex: 9999 }}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography
          style={{ fontSize: 18, fontWeight: "600" }}
          id="modal-title"
          variant="h6"
          component="h3"
        >
          コメントに表示するお名前
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            textAlign: "center",
            marginTop: 4,
            marginBottom: 4,
          }}
          component="p"
        >
          コメント欄に表示させるお名前を以下の3つから選んでください。
        </Typography>
        {options?.map((item) => (
          <Button
            onClick={() => handleOptionSelect(item?.value)}
            sx={buttonStyle}
          >
            {item?.name}
          </Button>
        ))}
        {/* <Button onClick={() => handleOptionSelect("Option 1")} sx={buttonStyle}>
          Option 1
        </Button>
        <Button onClick={() => handleOptionSelect("Option 2")} sx={buttonStyle}>
          Option 2
        </Button>
        <Button onClick={() => handleOptionSelect("Option 3")} sx={buttonStyle}>
          Option 3
        </Button> */}
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const buttonStyle = {
  color: colors.farm_blue200,
  fontWeight: "600",
};

export default OptionsModal;
