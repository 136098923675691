import React, { useState, useCallback, useRef, useEffect } from "react";
import { TextField, InputAdornment, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import appServices from "../../services/app-services/appServices";
import axios, { CancelTokenSource } from "axios";

interface SearchBarProps {
  onResults: (data: any[]) => void;
  reSearch: boolean;
  focus?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onResults,
  reSearch,
  focus = false,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  // Debounce function to limit the number of API calls
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  // Function to fetch search results
  const fetchSearchResults = async (term: string) => {
    if (term) {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel("Operation canceled due to new request.");
      }
      cancelTokenRef.current = axios.CancelToken.source();

      try {
        setLoading(true);
        const posts = await appServices.search(term, {
          cancelToken: cancelTokenRef.current.token,
        });
        onResults(posts);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching search results:", error);
          onResults([]);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      onResults([]);
    }
  };

  // Debounced version of fetchSearchResults
  // eslint-disable-next-line
  const debouncedFetchSearchResults = useCallback(
    debounce(fetchSearchResults, 800),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedFetchSearchResults(term);
  };

  useEffect(() => {
    if (reSearch && searchTerm) {
      debouncedFetchSearchResults(searchTerm);
    }
    // eslint-disable-next-line
  }, [reSearch]);

  return (
    <>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        focused={focus}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress size={24} />
            </InputAdornment>
          ) : null,
        }}
      />
    </>
  );
};

export default SearchBar;
