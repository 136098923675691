import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import setAuthorizationToken from "./services/axios-util/set-authorization-token";
// import userAuth from "./util/auth-util";
// import userAuth from "./util/auth-util";

/** A higher-order component with conditional routing logic */
export function withCondition(
  Component: FunctionComponent,
  redirectTo: string
) {
  return function InnerComponent(props: any) {
    // const isAuthenticated = userAuth.isAuthorized();
    const { admin } = useSelector((state: any) => state.adminAuth);
    if (admin.auth_token) setAuthorizationToken(admin.auth_token);
    return admin.auth_token ? (
      <Component {...props} />
    ) : (
      <Navigate to={redirectTo} replace />
    );
  };
}

export const checkLoggedInAdmin = (Component: React.FunctionComponent) =>
  withCondition(Component, "/login");
