import { Box, IconButton, Typography, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { colors } from "../../common/colors";

interface Props {
  user: { id: string; nickname: string; image: string; user_id: string };
  isEdit?: boolean;
  onDelete(user_id: string): void;
}

function SelectedUser({ user, isEdit = false, onDelete }: Props) {
  const { user: loggedUser } = useSelector((state: any) => state.userAuth);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "6px",
        borderRadius: "2px",
        border: `1px solid ${colors.farm_green200}`,
        backgroundColor: "#f9f9f9",
        marginRight: "4px",
        marginBottom: "2px",
      }}
    >
      <Avatar
        sx={{ marginRight: "8px" }}
        src={"data:image/jpeg;base64," + user?.image}
      >
        {!user.image && user?.nickname?.charAt(0)}
      </Avatar>
      <Typography
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          minWidth: 0, // Allows text to shrink and ellipsis
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {user?.nickname}&nbsp;
      </Typography>
      {!isEdit && (
        <>
          {loggedUser?.id !== user?.user_id && (
            <IconButton
              aria-label="delete"
              onClick={() => onDelete(user.id)}
              sx={{ color: "red", padding: 0, margin: 0 }}
            >
              <img
                alt=""
                style={{ borderRadius: 50, height: 30 }}
                src={require("../../assets/images/remove_user.png")}
              />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
}

export default SelectedUser;
