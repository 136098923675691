import { colors } from "../common/colors";

export const styles = {
  tabTitle:{
    paddingTop:8,
    fontSize:12
  },
  tabContainer: {
    borderTopColor: "#86BB57",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    margin: 0,
    padding: 0,
    paddingLeft: 0,
    borderBottomColor: "#86BB57",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    width: "100%",

    justifyContent: "space-between",
  },
  activeTab: {
    paddingLeft:0,
    paddingRight:0,
    color:colors.farm_green500,
    backgroundColor: 'white',
    borderRadius: 0,
    boxShadow: "0 0 0 0",
    border: 0,
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "500",
    borderLeft: 1,
    borderLeftColor: colors.farm_gray100,
    borderLeftStyle: "solid",
    width: "100%",
    maxWidth: 174,
    // paddingTop: 10,
    paddingBottom: 10,
    margin: 0,
    
  },
  unActiveTab: {
    paddingLeft:0,
    paddingRight:0,
    fontFamily: "Poppins",
    color: "black",
    backgroundColor: 'white',
    borderRadius: 0,
    boxShadow: "0 0 0 0",
    border: 0,
    fontSize: 16,
    fontWeight: "500",
    width: "100%",
    borderLeft: 1,
    borderLeftColor: colors.farm_gray100,
    borderLeftStyle: "solid",
    maxWidth: 174,
    minWidth:30,
    // paddingTop: 10,
    paddingBottom: 10,
    margin: 0,
  },
  activeTabPost: {
    paddingLeft:0,
    paddingRight:0,
    color: "white",
    // backgroundColor:  colors.farm_green500,
    borderRadius: 0,
    boxShadow: "0 0 0 0",
    border: 0,
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "500",
    borderLeft: 1,
    borderLeftColor: colors.farm_gray100,
    borderLeftStyle: "solid",
    width: "100%",
    maxWidth: 174,
    paddingTop: 10,
    paddingBottom: 10,
    margin: 0,
  },
  unActiveTabPost: {
    paddingLeft:0,
    paddingRight:0,
    fontFamily: "Poppins",
    color: "black",
    // backgroundColor:colors.farm_green100,
    borderRadius: 0,
    boxShadow: "0 0 0 0",
    border: 0,
    fontSize: 16,
    fontWeight: "500",
    width: "100%",
    borderLeft: 1,
    borderLeftColor: colors.farm_gray100,
    borderLeftStyle: "solid",
    maxWidth: 174,
    minWidth:30,
    paddingTop: 10,
    paddingBottom: 10,
    margin: 0,
  },
  importFonts: {
    fontFamily: "'Montserrat', sans-serif",
  },
  body: {
    background: "#f6f5f7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontFamily: "'Montserrat', sans-serif",
    height: "100vh",
    margin: "-20px 0 50px",
  },
  h1: {
    fontWeight: "bold",
    margin: 0,
  },
  h2: {
    textAlign: "center",
  },
  p: {
    fontSize: "14px",
    fontWeight: 100,
    lineHeight: "20px",
    letterSpacing: "0.5px",
    margin: "20px 0 30px",
  },
  span: {
    fontSize: "12px",
  },
  a: {
    color: "#333",
    fontSize: "14px",
    textDecoration: "none",
    margin: "15px 0",
  },
  button: {
    borderRadius: "20px",
    border: "1px solid #FF4B2B",
    backgroundColor: "#FF4B2B",
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "12px 45px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    transition: "transform 80ms ease-in",
  },
  buttonActive: {
    transform: "scale(0.95)",
  },
  buttonFocus: {
    outline: "none",
  },
  buttonGhost: {
    backgroundColor: "transparent",
    borderColor: "#FFFFFF",
  },
  form: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 50px",
    height: "100%",
    textAlign: "center",
  },
  input: {
    backgroundColor: "#eee",
    border: "none",
    padding: "12px 15px",
    margin: "8px 0",
    width: "100%",
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    position: "relative",
    overflow: "hidden",
    width: "768px",
    maxWidth: "100%",
    minHeight: "480px",
  },
  formContainer: {
    position: "absolute",
    top: 0,
    height: "100%",
    transition: "all 0.6s ease-in-out",
  },
  signInContainer: {
    left: 0,
    width: "50%",
    zIndex: 2,
  },
  rightPanelActiveSignIn: {
    transform: "translateX(100%)",
  },
  signUpContainer: {
    left: 0,
    width: "50%",
    opacity: 0,
    zIndex: 1,
  },
  rightPanelActiveSignUp: {
    transform: "translateX(100%)",
    opacity: 1,
    zIndex: 5,
    animation: "show 0.6s",
  },
  overlayContainer: {
    position: "absolute",
    top: 0,
    left: "50%",
    width: "50%",
    height: "100%",
    overflow: "hidden",
    transition: "transform 0.6s ease-in-out",
    zIndex: 100,
  },
  rightPanelActiveOverlayContainer: {
    transform: "translateX(-100%)",
  },
  overlay: {
    background: "linear-gradient(to right, #FF4B2B, #FF416C)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "0 0",
    color: "#FFFFFF",
    position: "relative",
    left: "-100%",
    height: "100%",
    width: "200%",
    transform: "translateX(0)",
    transition: "transform 0.6s ease-in-out",
  },
  rightPanelActiveOverlay: {
    transform: "translateX(50%)",
  },
  overlayPanel: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 40px",
    textAlign: "center",
    top: 0,
    height: "100%",
    width: "50%",
    transform: "translateX(0)",
    transition: "transform 0.6s ease-in-out",
  },
  overlayLeft: {
    transform: "translateX(-20%)",
  },
  rightPanelActiveOverlayLeft: {
    transform: "translateX(0)",
  },
  overlayRight: {
    right: 0,
    transform: "translateX(0)",
  },
  rightPanelActiveOverlayRight: {
    transform: "translateX(20%)",
  },
  socialContainer: {
    margin: "20px 0",
  },
  socialContainerA: {
    border: "1px solid #DDDDDD",
    borderRadius: "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 5px",
    height: "40px",
    width: "40px",
  },
  footer: {
    backgroundColor: "#222",
    color: "#fff",
    fontSize: "14px",
    bottom: 0,
    position: "fixed",
    left: 0,
    right: 0,
    textAlign: "center",
    zIndex: 999,
  },
  footerP: {
    margin: "10px 0",
  },
  footerI: {
    color: "red",
  },
  footerA: {
    color: "#3c97bf",
    textDecoration: "none",
  },
  // GroupTable Component Start

  titletable: { color: colors.farm_blue200, fontWeight: "600" },
  desTable: { fontWeight: "300" },
  tableDialog: {
    marginBottom: 0,
    height: 15,
    marginTop: 10,
  },
  tableCloseIcon: { color: "gray", width: 15, height: 15 },
  tableDialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCreateUserContainer: {
    alignSelf: "flex-start",
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "flex-start",
    alignContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  tableTitleUser: {
    fontSize: 20,
    alignSelf: "center",
    marginBottom: 30,
    fontWeight: "600",
    color: colors.farm_blue200,
  },
  tableFieldSec: {
    flexDirection: "row",
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  tableDialogSubContent: {
    alignSelf: "flex-start",
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "flex-start",
    alignContent: "center",
    display: "flex",
  },
  tableContainer: {
    flexDirection: "row",
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  tableInputField: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: "48%",
  },
  tableInputFieldTitle: {
    fontSize: 14,
    color: colors.farm_blue200,
    marginBottom: 5,
    fontWeight: "500",
  },
  tableInputFieldTxt: { fontSize: 22, width: "100%" },
  tableInputSec: {
    marginTop: 10,
    alignSelf: "center",
    alignContent: "center",
    width: "80%",
  },
  tableInputFields: {
    display: "flex",
    alignSelf: "center",
    width: "100%",
  },
  tableCreateBtn: {
    alignSelf: "center",
    borderRadius: 20,
    backgroundColor: colors.farm_blue200,
    marginTop: 30,
    marginBottom: 20,
    width: 100,
  },
  tableCreateBtnTxt: {
    fontWeight: "400",
    color: "#ffffff",
    fontSize: 14,
  },
  tableUserName: { color: colors.farm_blue200, fontWeight: "600" },

  // GroupTable Component Ends
  adminDialogTitle: {
    fontWeight: "400",
    color: colors.farm_blue200,
    textAlign: "center",
  },
  /////////////////////////////////////   MyFarm Starts   /////////////////////////////////
  farmContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    overflow: "hidden",
  },
  farmProfileInfo: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: 60,
  },
  farmProfileIcon: {
    width: 70,
    height: 70,
    alignSelf: "center",
    marginBottom: 20,
    borderRadius:35
  },
  farmDivider: { marginTop: 40, backgroundColor: "gray" },
  farmInfoTitle: {
    fontSize: 16,
    color: "#2596be",
    fontWeight: "500",
    justifyContent: "center",
    alignSelf: "center",
  },
  farmAccordion: { boxShadow: "none" ,margin:0,padding:0},
  farmDivider2: { alignSelf: "center", backgroundColor: "gray" },
  farmStats: { display: "flex", flexDirection: "column" },
  farmAccordionDetail: { display: "flex", flexDirection: "column" },
  farmDivider3: { width: "50%", marginTop: 10 },
  farmDivider4: {
    alignSelf: "center",
    marginTop: 5,
    marginBottom: 15,
    backgroundColor: "gray",
  },
  /////////////////////////////////////   MyFarm Ends   /////////////////////////////////

  /////////////////////////////////////   LoginAdmin Starts   /////////////////////////////////
  loginContainer: {
    backgroundColor: "whitesmoke",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  loginSubContainer: {
    alignSelf: "center",
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    // flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  loginCLogo: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginBottom: 20,
  },
  loginTitle: {
    fontSize: 20,
    color: "#313131",
    alignSelf: "center",
    marginBottom: 30,
  },
  loginTitleSec: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    minWidth: 300,
  },
  loginFieldTitle: {
    fontSize: 16,
    color: "#2596be",
    marginBottom: 5,
    fontWeight: "500",
  },
  loginTxtField: { fontSize: 22 },
  loginPasswordFiledSec: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: 20,
    minWidth: 300,
  },
  loginField: { fontSize: 22 },
  loginFieldTxt: {
    marginTop: 10,
    minWidth: 300,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    maxWidth: 300,
  },
  loginForgotTxt: {
    fontSize: 14,
    color: "orange",
    textDecoration: "underline",
  },
  loginBtnLogin: {
    alignSelf: "center",
    borderRadius: 20,
    backgroundColor: colors.farm_blue200,
    marginTop: 30,
    marginBottom: 20,
  },
  loginBtnTxt: { minWidth: 200, color: "#ffffff" },
  loginUserFiledTxt: { fontSize: 22 },
  loginUserPassField: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: 20,
    minWidth: 300,
    alignSelf: "center",
  },
  loginUserPassFieldTxt: {
    fontSize: 16,
    color: "#2596be",
    marginBottom: 5,
    fontWeight: "500",
  },
  loginuserForgotSec: {
    marginTop: 10,
    minWidth: 300,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    maxWidth: 300,
  },
  loginUserForgotSecTxt: {
    fontSize: 14,
    color: "orange",
    textDecoration: "underline",
  },
  loginUserLoginBtn: {
    alignSelf: "center",
    borderRadius: 20,
    backgroundColor: colors.farm_blue200,
    marginTop: 30,
    marginBottom: 20,
  },
  loginUserLoginBtnTxt: { minWidth: 200, color: "#ffffff" },

  /////////////////////////////////////   LoginAdmin Ends   /////////////////////////////////

  /////////////////////////////////////   AdminHome Start   /////////////////////////////////

  adminUserDialogTitle: {
    fontWeight: "400",
    color: colors.farm_blue200,
    // textAlign: "center",
  },
  adminUserDialogContent: {
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
    // flexDirection: "column",
  },
  adminUserDialogTitleName: {
    // flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    height: "20%",
    alignSelf: "center",
  },
  adminUserName: {
    fontSize: 16,
    color: colors.farm_blue200,
    marginBottom: 10,
    fontWeight: "500",
  },
  adminUserInputField: {
    fontSize: 22,
    maxWidth: 300,
    marginBottom: 25,
  },
  adminUserBtn: {
    alignSelf: "center",
    borderRadius: 15,
    backgroundColor: colors.farm_blue200,
  },
  adminUserBtnTxt: {
    minWidth: 150,
    color: colors.farm_white,
  },
  adminUserCancelBtnTxt: { color: "gray" },

  /////////////////////////////////////   AdminHome Ends   /////////////////////////////////

  /////////////////////////////////////   Community List page Starts   /////////////////////////////////

  communityContainer: { padding: 10 },
  communiTitle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  communiTitleTxt: {
    fontSize: 24,
    fontFamily: "mplus-1c-regular",
    paddingLeft: 6,
    fontWeight: "600",
  },
  communiTitleDescrip: {
    fontFamily: "mplus-1c-regular",
    fontSize: 16,
  },
  communiNormalTxt: {
    textAlign: "center",
    fontFamily: "mplus-1c-bold",
    fontSize: 16,
  },
  detailCommentNormalTxt: {
    fontFamily: "mplus-1c-bold",
    fontSize: 16,
    margin: 0,
  },
  /////////////////////////////////////   Community List page Ends   /////////////////////////////////

  /////////////////////////////////////   Event List page Starts   /////////////////////////////////
  loginUserContainer: { justifyContent: "center" },
  loginUsetrLeftSec: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    alignContent: "flex-end",
  },
  loginUserImg: { width: 400, height: 400, alignSelf: "flex-end" },
  loginUserRightSec: {
    paddingBottom: 20,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    alignContent: "center",
    fontWeight: "600",
    display: "flex",
  },
  loginUserCLogo: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginBottom: 20,
  },
  /////////////////////////////////////   Event List page Ends   /////////////////////////////////

  /////////////////////////////////////   Intro Page Starts   /////////////////////////////////
  introContainer: {
    backgroundColor: "whitesmoke",
    overflow: "hidden",
    paddingBottom: 30,
  },
  introSliderSec: {
    margin: 30,
    backgroundColor: "white",
    marginTop: 30,
    marginBottom: 30,
    padding: 10,
    paddingTop: 10,
    borderRadius: 20,
    borderColor:colors.farm_green200,
    borderStyle:'solid',
    
  },
  introSliderTxt: {
    fontFamily: "mplus-1c-bold",
    fontSize: 24,
    margin: 0,
  },
  introSliderDsc: {
    fontFamily: "mplus-1c-regular",
    fontSize: 24,
    margin: 0,
  },
  introSliderImg: {
    // margin: 10,
    backgroundColor: "white",
    height: '100%',
    width: '100%',
    // maxHeight:400,
    maxWidth:400
  },
  introSliderBtnSec: {
    width: "100%",
    marginTop: 20,
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    display: "flex",
  },
  introSliderBtn: {
    alignSelf: "center",
    borderRadius: 20,
    backgroundColor: colors.farm_blue200,
  },
  introSliderNextbtn: { minWidth: 200 },
  /////////////////////////////////////   Intro Page Ends   /////////////////////////////////

  /////////////////////////////////////   Detail View Page Starts   /////////////////////////////////
  detailContainer: { padding: 10 },
  detailCarousel: {
    backgroundColor: "white",
    paddingBottom: 10,
  },
  detailCarouselImg: {
    backgroundColor: colors.farm_green100,
    height: 250,
    width: "100%",
  },
  detailStateTxt: {
    fontSize: 24,
    fontFamily: "mplus-1c-regular",
    paddingLeft: 6,
    fontWeight: "600",
  },
  detailPostDate: {
    color: colors.farm_gray200,
    fontFamily: "mplus-1c-regular",
    fontSize: 12,
  },
  detailListItem: {
    borderTopWidth: 1,
    borderTopColor: colors.farm_gray100,
    borderBottomWidth: 1,
    borderBottomColor: colors.farm_gray100,
  },
  detailSummaryTxt: {
    color: colors.farm_gray200,
    fontFamily: "mplus-1c-bold",
    fontSize: 12,
  },
  detailStatLike: {
    fontFamily: "mplus-1c-bold",
    fontSize: 12,
  },
  detailVoteSec: { display: "flex" },
  detailVoteSubSec: {
    display: "block",
    
    width: 60,
  },
  detailVoteTxt: { fontFamily: "mplus-1c-bold", fontSize: 12 },
  detailCommentSec: { display: "block", 

   width: 69
 },
 highlightWordText:{color:'red'},
  detailCommentTxt: { fontFamily: "mplus-1c-bold", fontSize: 12 },
  detailVoteContainer: { display: "flex", justifyContent: "space-between" },
  detailVoteNormalTxt: { fontFamily: "mplus-1c-bold", fontSize: 18 },
  detailVoteStatusTxt: {
    display: "flex",
    alignItems: "center",
    color: colors.farm_green500,
  },
  detailStatusContainer: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
  },
  detailCommentBox: {
    marginTop:2,
    flexShrink: 1,
    backgroundColor: colors.farm_gray10,
    borderRadius: 4,
    padding: 2,
  },
  /////////////////////////////////////   Detail View Page Ends   /////////////////////////////////

  /////////////////////////////////////   CERItem Component Starts   /////////////////////////////////
  cerListContainer: { paddingLeft: 4, paddingRight: 4, borderRadius: 4 },
  cerListImg: {
    height: 110,
    width: 170,
    borderWidth: 1,

    borderStyle: "solid",
    borderRadius: 10,
  },
  /////////////////////////////////////   CERItem Component Ends   /////////////////////////////////

  /////////////////////////////////////   DropDown Component Starts   /////////////////////////////////
  dropDownContainer: {
    marginTop: 4,
    width: "100%",
    justifyContent: "left",
  },
  dropDownLabel: { color: "black", fontWeight: "600" },
  dropDownSelected: { fontFamily: "mplus-1c-bold", justifyContent: "left" },
  dropDownMenuItem: { fontFamily: "mplus-1c-bold" },
  /////////////////////////////////////   DropDown Component Ends   /////////////////////////////////

  /////////////////////////////////////   GroupTable Component Starts   /////////////////////////////////
  groupDialog: {
    marginBottom: 0,
    height: 15,
    marginTop: 10,
  },
  groupBadge: { color: "gray", width: 15, height: 15 },
  groupDialogContainer: {
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  groupTitleName: {
    fontSize: 12,
    color: colors.farm_blue200,
    marginBottom: 10,
    fontWeight: "500",
  },
  groupTextField: {
    fontSize: 14,
    padding: 0,
    marginBottom: 10,
  },
  groupDialogBtn: {
    alignSelf: "center",
    borderRadius: 15,
    backgroundColor: colors.farm_blue200,
  },
  groupDialogBtnTxt: {
    minWidth: 50,
    color: colors.farm_white,
    fontSize: 10,
  },
  groupDialogClose: {
    marginBottom: 0,
    height: 15,
    marginTop: 10,
  },
  groupDialogCloseIcon: { color: "gray", width: 15, height: 15 },
  /////////////////////////////////////   GroupTable Component Ends   /////////////////////////////////

  /////////////////////////////////////   See More Large Component Starts   /////////////////////////////////
  seeMoreImg: {
    backgroundColor: colors.farm_green100,
    height: 242,
    width: "100%",
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: "solid",
    
  },
  seeMoreSec: { display: "block" },
  seeMoreEventTxt: { fontSize: 16, color: "black" },
  seeMoreImgSmall: {
    backgroundColor: colors.farm_green100,
    height: 102,
    width: 160,
    borderRadius: 6,
    borderWidth: 1,
    borderColor:colors.farm_gray100,
    borderStyle: "solid",
  },


  /////////////////////////////////////   See More Large Component Ends   /////////////////////////////////

  /////////////////////////////////////   VotingItem Component Starts   /////////////////////////////////
voteListSec:{ paddingLeft: 4, paddingRight: 4, borderRadius: 4 },
voteListImg:{
  height: 110,
  width: 170,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 10,
  zIndex: 0,
},
voteListIconSec:{
  alignItems: "center",
  display: "flex",
  padding: 2,
  justifyContent: "space-between",
},
voteListTotal:{ fontSize: 14, paddingLeft: 6 },
voteListLikes:{ fontSize: 14, fontFamily: "Poppins", paddingLeft: 6 },
  /////////////////////////////////////   VotingItem Component Ends   /////////////////////////////////


  tagStyle:{backgroundColor:colors.farm_gray200,borderRadius:2},



//////tag input/////
/////////////////////////////////////   ECRHorizontalList Component Starts   /////////////////////////////////
  ecrContainer:{
    paddingTop: 20,
    paddingBottom: 50,
    borderBottomWidth: 4,
  },
  ecrSubContainer:{
    alignItems: "center",
    display: "flex",
    padding: 2,
    paddingBottom: 10,
  },
  ecrBtnSec:{ width: "25%", display: "flex", justifyContent: "end" },
  ecrDesTxt:{
    fontSize: 16,
    fontFamily: "mplus-1c-regular",
    padding: 6,
    margin: 0,
  },
  ecrVoteItem:{ display: "flex", overflow: "scroll" },


  /////////////////////////////////////   ECRHorizontalList Component Ends   /////////////////////////////////


  //////tag input/////

};

  




