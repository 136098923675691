import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { T } from "react-translator-component";
import "react-multi-carousel/lib/styles.css";
import ButtonOutlinedCentered from "./ButtonOutlined";
import { colors } from "../common/colors";
import Separator from "./Separator";
import SelectedUser from "./items/SelectedUser";
import { useState } from "react";
import AddUserModel from "./Models/AddUserModal";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { removeSelectedUser } from "../redux/Reducers/postReducer";
import { useAppDispatch } from "../redux/store";
import Carousel from "react-multi-carousel";
import "../App.css";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align items to the bottom */
  z-index: 9999;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 100%;
  height: 92%;
  overflow-y: auto;
  z-index: 9999;
`;

interface Props {
  isPrivate: string;
  isEdit: boolean;
  onChange(isPrivate: string): void;
}

export default function PPThread({ isPrivate, isEdit, onChange }: Props) {
  const dispatch = useAppDispatch();
  const { selectedUsers } = useSelector((state: any) => state.posts);

  const [isUserModelVisible, setIsUserModelVisible] = useState(false);
  // const [threadType, setThreadType] = useState(isPrivate);

  const handleAddUserClick = () => {
    setIsUserModelVisible(true);
  };

  const handleCloseModal = () => {
    setIsUserModelVisible(false);
  };
  // let width = window.innerWidth * 0.9;

  const handleDelete = (userId: any) => {
    dispatch(removeSelectedUser(userId));
  };

  const addUsers = (users: any) => {
    // setUsers(users);
    handleCloseModal();
  };
  const handleThreadType = (value: any) => {
    // console.log(value.target.value);
    // setThreadType(value.target.value);
    onChange(value.target.value);
  };
  // useEffect(() => {
  //   if (isUserModelVisible) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [isUserModelVisible]);
  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ padding: 10 }}>
        <Typography
          sx={{ fontSize: 16, fontWeight: "600", flex: 1, textAlign: "center" }}
        >
          {T("Please_select_post_type")}
        </Typography>
        <Separator color={colors.farm_green200} />
        <RadioGroup
          style={{ pointerEvents: isEdit ? "none" : "auto" }}
          aria-labelledby="radio-buttons-group-label"
          defaultValue="0"
          name="radio-buttons-group"
          value={isPrivate}
          onChange={handleThreadType}
        >
          <FormControlLabel
            value="0"
            control={
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: colors.farm_blue200,
                  },
                }}
              />
            }
            label={
              <div>
                <Typography
                  sx={{ fontWeight: "600", mv: 12 }}
                  variant="body2"
                  color="textPrimary"
                >
                  オープン投稿
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  投稿された内容は「みんなの投稿」に表示されます。
                </Typography>
              </div>
            }
            labelPlacement="end"
            sx={{
              paddingTop: 2,
              alignItems: "flex-start",
            }}
          />
          <FormControlLabel
            value="1"
            control={
              <Radio
                sx={{
                  "&, &.Mui-checked": {
                    color: colors.farm_blue200,
                  },
                }}
              />
            }
            label={
              <div>
                <Typography
                  sx={{ fontWeight: "600", mv: 12 }}
                  variant="body2"
                  color="textPrimary"
                >
                  プライベート投稿
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  投稿された内容は指定したユーザーだけが見ることができます。
                  学校、会社、友達など内輪で決め事をする際プライベート投稿は便利です。
                </Typography>
              </div>
            }
            labelPlacement="end"
            sx={{
              paddingTop: 6,
              alignItems: "flex-start",
            }}
          />
        </RadioGroup>
      </div>
      {isPrivate === "1" && (
        <div
          style={{
            marginBottom: 8,
            padding: 10,
            backgroundColor: colors.farm_gray10,
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="textPrimary">
              この投稿を見られるユーザー
            </Typography>
            <Typography
              variant="body2"
              style={{
                backgroundColor: colors.farm_green500,
                borderRadius: "4px",
                color: "white",
                padding: "2px 8px",
                marginLeft: "8px",
                marginBottom: "2px",
              }}
            >
              {selectedUsers?.length}
            </Typography>
          </Box>

          <Separator color={colors.farm_green200} />
          {/* <div> */}
          <Carousel
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 1024 },
                items: 5, // Number of items to show on large screens
              },
              desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
              tablet: { breakpoint: { max: 768, min: 464 }, items: 2 },

              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2, // Ensure at least 2 items on mobile screens
              },
            }}
            swipeable={true} // Allows swipe gestures on mobile devices
            draggable={true} // Enables dragging on desktop
            showDots={false} // Optionally show/hide dots
            arrows={true} // Optionally show/hide arrows
            infinite={false} // Optionally make the carousel infinite
            keyBoardControl={true} // Allows keyboard control
            itemClass="carousel-item-padding" // Item class
          >
            {/* <Carousel responsive={responsive}> */}
            {[...selectedUsers]
              ?.slice()
              .reverse()
              .map((user: any) => (
                <SelectedUser
                  isEdit={isEdit}
                  key={user.id}
                  user={user}
                  onDelete={handleDelete}
                />
              ))}
            {/* </Carousel> */}
          </Carousel>
          {/* </div> */}
          {/* {!isEdit && ( */}
          <ButtonOutlinedCentered
            title="[＋] ユーザーを追加する"
            onClick={handleAddUserClick}
          />
          {/* )} */}
          {isUserModelVisible && (
            <ModalBackground onClick={handleCloseModal}>
              <ModalContainer onClick={(e: any) => e.stopPropagation()}>
                <AddUserModel
                  isEdit={false}
                  closeModal={(users) => addUsers(users)}
                />
              </ModalContainer>
            </ModalBackground>
          )}
        </div>
      )}
    </div>
  );
}
