import {
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  styled as styled2,
} from "@mui/material";
import { useEffect, useState } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { colors } from "../../common/colors";
import { T } from "react-translator-component";

import { styles } from "../../pages/styles";

import appServices from "../../services/app-services/appServices";
import moment from "moment";
import { useSelector } from "react-redux";
// import { T } from "react-translator-component";
// Dummy data for users

let width = window.innerWidth * 0.9;
let height = window.innerHeight - 400;
// Styled components

const Container = styled.div`
  width: ${width};
  height: ${height};
  margin-top: 7px;
  padding: 12px;
  padding-top: 0;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
  border: 2 sol;
`;

const ParentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ClearButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: white;
`;

// Main component

interface Props {
  post: any;
  closeModal(): void;
}
const VotesModel = ({ closeModal, post }: Props) => {
  // const dispatch = useAppDispatch();

  // const [loading, setLoading] = useState(false);
  // const [snakeText, setSnakeText] = useState("");
  const [postItem, setPostItem] = useState(post);
  const [viewStatus, setViewStatus] = useState(false);
  const [voteOptions, setVoteOptions] = useState<any>(postItem?.vote_options);

  const { user } = useSelector((state: any) => state.userAuth);

  const calculatePercentage = (options: any[]) => {
    // Step 1: Calculate total votes
    const totalVotes = options.reduce(
      (total: any, option: { votes_count: any }) => total + option.votes_count,
      0
    );

    // Step 2: Calculate and add percentage to each option
    return options.map((option) => {
      const percentage =
        totalVotes > 0 ? (option.votes_count / totalVotes) * 100 : 0;
      return { ...option, percentage: percentage.toFixed(0) };
    });
  };

  useEffect(() => {
    console.log(postItem?.vote_options);
    const votes = calculatePercentage(postItem?.vote_options);
    setVoteOptions(votes);
  }, [postItem]);

  const BorderLinearProgress = styled2(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colors.farm_green500,
    },
  }));

  const handleVoteAnonymouslyNo = async (vote: any) => {
    try {
      // setLoading(true);
      const addvote = await appServices.createVote(vote.id, false);
      try {
        getSinglePost();
      } catch (error) {}
      console.log(addvote);
      // setSnakeText(voteaddedtext);
      setViewStatus(true);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  const handleClickOpen = (vote: any) => {
    console.log(vote);
    const dateToCheck = moment(postItem?.expiry_date);
    const todayDate = moment();
    // console.log("dateToCheck", postState?.expiry_date, dateToCheck);
    // console.log("todayDate", todayDate);
    if (dateToCheck.isSameOrAfter(todayDate, "day")) {
      console.log("The date is the same as or after today's date.");
      if (user?.id_kind === "2") {
      } else {
        if (postItem?.user?.id === user?.id) return;
        if (postItem?.voted_info?.voted_by_me) return;
        console.log(postItem?.voted_info?.voted_by_me);
        // setVoteOption(vote);
        handleVoteAnonymouslyNo(vote);
        // setOpen(true);
      }
    } else {
      console.log("The date is before today's date.");
    }
  };

  // const Yourcommentaddedtext = T("Your_comment_added");

  const getSinglePost = async () => {
    const post = await appServices.getSinglePost(postItem?.id);

    setPostItem(post.post);
  };

  return (
    <Container>
      {/* <LoadingModal
        visible={isLoadingUsers}
        handleClose={() => console.log("")}
      /> */}
      <ParentDiv>
        <ClearButton
          onClick={() => {
            console.log("close");
            closeModal();
          }}
        >
          <Typography
            sx={{ fontSize: 12, color: colors.farm_gray200 }}
            variant="body2"
            color="textPrimary"
          >
            閉じる
          </Typography>
          <KeyboardArrowDownIcon />
        </ClearButton>
      </ParentDiv>
      <div style={{ height: 500, overflow: "auto" }}>
        <List
          sx={{
            // selected and (selected + hover) states

            // hover states
            "& .MuiListItemButton-root:hover": {
              bgcolor: colors.farm_gray10,
              "&, & .MuiListItemIcon-root": {
                color: "black",
              },
              "&$focusVisible": {
                backgroundColor: colors.farm_gray10,
              },
              "& .MuiListItemButton-root:touch": {
                bgcolor: colors.farm_gray10,
                "&, & .MuiListItemIcon-root": {
                  color: "black",
                },
              },
            },
          }}
        >
          {voteOptions?.map((item: any) => (
            <ListItemButton
              key={item.id}
              onClick={() => handleClickOpen(item)}
              sx={{
                backgroundColor: colors.farm_gray10,
                borderRadius: 2,
                paddingTop: 2,
                paddingBottom: 2,
                margin: 1,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor:
                  postItem?.voted_info?.my_votes[0] === item.id
                    ? colors.farm_green500
                    : colors.farm_green100,
                fontFamily: "mplus-1c-bold",
              }}
              component="p"
            >
              <ListItemText
                primary={
                  <span
                    style={{
                      ...styles.communiTitleDescrip,
                      wordWrap: "break-word",
                    }}
                  >
                    {item.option_text}
                  </span>
                }
                secondary={
                  viewStatus ? (
                    <div style={styles.detailVoteStatusTxt}>
                      <BorderLinearProgress
                        style={{ width: "90%" }}
                        variant="determinate"
                        value={item.percentage}
                      />
                      &nbsp;{item.percentage}&nbsp;%
                    </div>
                  ) : null
                }
              />
            </ListItemButton>
          ))}
        </List>
        <div style={styles.detailStatusContainer}>
          {voteOptions?.length !== 0 ? (
            <Button
              onClick={() => setViewStatus(!viewStatus)}
              style={{ borderRadius: 20 }}
              color={"secondary"}
              size="small"
              variant="outlined"
            >
              {viewStatus ? "投票に戻る" : T("View_voting_status")}
            </Button>
          ) : (
            <p style={styles.detailPostDate}>投票オプションはありません</p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default VotesModel;
