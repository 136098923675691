import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import tab1 from "../../assets/images/tabs/tab1.png";
import tab2 from "../../assets/images/tabs/tab2.png";
import tab3 from "../../assets/images/tabs/tab3.png";
import tab4 from "../../assets/images/tabs/tab4.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { colors } from "../../common/colors";

interface Props {
  onChange(value: any): void;
}

const TabComponent = ({ onChange }: Props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "8px",
        boxShadow: "0px 0px 15px rgba(0,0,0,0.15)",
        backgroundColor: "#fff",
        padding: "8px 0",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        centered
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        <Tab
          icon={
            <img
              style={{
                height: 62,
                width: 62,
                borderWidth: value === 0 ? 2 : 0,
                borderStyle: "solid",
                borderRadius: 62,
                paddingTop: 2,
              }}
              src={tab1}
              alt="All"
            />
          }
          label={
            <div>
              <Typography>全て</Typography>

              <PlayArrowIcon
                style={{
                  transform: "rotate(-90deg)",
                  color: value === 0 ? colors.farm_green150 : "#FFFFFF",
                }}
              />
            </div>
          }
          sx={{
            minWidth: 0,
            padding: "8px 0",
            marginBottom: -2,
            textTransform: "none",
            color: value === 0 ? "#4CAF50" : "inherit",
            "& .MuiTab-iconWrapper": { fontSize: 30 },
          }}
        />
        <Tab
          icon={
            <img
              style={{
                height: 62,
                width: 62,
                borderWidth: value === 1 ? 2 : 0,
                borderStyle: "solid",
                borderRadius: 62,
                padding: 1,
              }}
              src={tab2}
              alt="All"
            />
          }
          label={
            <div>
              <Typography style={{ fontSize: 14 }}>コミュニティ</Typography>

              <PlayArrowIcon
                style={{
                  transform: "rotate(-90deg)",
                  color: value === 1 ? colors.farm_green150 : "#FFFFFF",
                }}
              />
            </div>
          }
          sx={{
            minWidth: 0,
            padding: "8px 0",
            textTransform: "none",
            marginBottom: -2,
            color: value === 1 ? "#4CAF50" : "inherit",
            "& .MuiTab-iconWrapper": { fontSize: 30 },
          }}
        />
        <Tab
          icon={
            <img
              style={{
                height: 62,
                width: 62,
                borderWidth: value === 2 ? 2 : 0,
                borderStyle: "solid",
                borderRadius: 62,
              }}
              src={tab3}
              alt="All"
            />
          }
          label={
            <div>
              <Typography>イベント</Typography>

              <PlayArrowIcon
                style={{
                  transform: "rotate(-90deg)",
                  color: value === 2 ? colors.farm_green150 : "#FFFFFF",
                }}
              />
            </div>
          }
          sx={{
            minWidth: 0,
            padding: "8px 0",
            textTransform: "none",
            marginBottom: -2,
            color: value === 2 ? "#4CAF50" : "inherit",
            "& .MuiTab-iconWrapper": { fontSize: 30 },
          }}
        />
        <Tab
          icon={
            <img
              style={{
                height: 62,
                width: 62,
                borderWidth: value === 3 ? 2 : 0,
                borderStyle: "solid",
                borderRadius: 62,
              }}
              src={tab4}
              alt="All"
            />
          }
          label={
            <div>
              <Typography>レポート</Typography>

              <PlayArrowIcon
                style={{
                  transform: "rotate(-90deg)",
                  color: value === 3 ? colors.farm_green150 : "#FFFFFF",
                }}
              />
            </div>
          }
          sx={{
            minWidth: 0,
            padding: "8px 0",
            marginBottom: -2,
            textTransform: "none",
            color: value === 3 ? "#4CAF50" : "inherit",
            "& .MuiTab-iconWrapper": { fontSize: 30 },
          }}
        />
      </Tabs>
      <Box
        style={{ marginLeft: 8, marginRight: 8 }}
        p={1}
        bgcolor={colors.farm_green150}
        borderRadius={3}
      >
        {value === 0 && <div style={{ fontSize: 14 }}>全ての投稿です。</div>}
        {value === 1 && (
          <div style={{ fontSize: 14 }}>
            気になることや、伝えたいことなどの投稿です。
          </div>
        )}
        {value === 2 && (
          <div style={{ fontSize: 14 }}>
            イベント情報、お祭り情報などの投稿です。
          </div>
        )}
        {value === 3 && (
          <div style={{ fontSize: 14 }}>
            行ってみた、体験してきたなどのレポートです。
          </div>
        )}
      </Box>
    </Box>
  );
};

export default TabComponent;
