import React, { useLayoutEffect } from "react";
import "./App.css";
import { checkLoggedIn } from "./routing";
import { HashRouter as Router, Routes, Route } from "react-router-dom"; // Change BrowserRouter to HashRouter
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import IntroPage from "./pages/IntroPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "./common/colors";
import MyFarm from "./pages/MyFarm";
import DashboardPage from "./pages/DashboardPage";
import CreatePost from "./pages/CreatePost";
import Header from "./components/Header";
import Unauthorized from "./unauthorized";
import VotingSeeMorePage from "./pages/VotingSeeMorePage";
import Login from "./pages/loginUser";
import DetailsViewPage from "./pages/DetailsViewPage";
import AdminHome2 from "./pages/AdminHome";
import { AuthProvider } from "react-auth-verification-context";
import { Translator, Config } from "react-translator-component";
import { useSelector } from "react-redux";
import PostView from "./pages/PostView";
import { language_config } from "./common/constants/language_config";
import UserInfo from "./pages/UserInfo";
import { checkLoggedInAdmin } from "./routing_admin";
import CategoryListPage from "./pages/CategoryListPage";
import setAuthorizationToken from "./services/axios-util/set-authorization-token";
import SuggestionBox from "./pages/SuggestionBox";
import FacilitatorList from "./pages/FacilitatorList";
import PostHistory from "./pages/MyFarmsPages/PostHistory";
import NavigatorLogin from "./pages/AdminNavigator/NavigatorLogin";
import SearchScreen from "./pages/phase2/SearchScreen";
import EveryOnesPostHistory from "./pages/MyFarmsPages/EveryOnesPostHistory";
import CommentHistory from "./pages/MyFarmsPages/CommentHistory";

// home page not in use new design////
const HomePage = checkLoggedIn(VotingSeeMorePage);
const AdminHome = checkLoggedInAdmin(AdminHome2);
const PostViewPage = checkLoggedInAdmin(PostView);
const CategoryPage = checkLoggedIn(CategoryListPage);
const FacilitatorPage = checkLoggedIn(FacilitatorList);
const SuggestionPage = checkLoggedIn(SuggestionBox);
const PostCreatePage = checkLoggedIn(CreatePost);
const Dashboard = checkLoggedIn(DashboardPage);
const MyFarmPage = checkLoggedIn(MyFarm);
const VotingSeeMore = checkLoggedIn(VotingSeeMorePage);
const DetailsView = checkLoggedIn(DetailsViewPage);
const PostHistoryScreen = checkLoggedIn(PostHistory);
const EveryOnesPostHistoryScreen = checkLoggedIn(EveryOnesPostHistory);
const CommentHistoryScreen = checkLoggedIn(CommentHistory);
const Search = checkLoggedIn(SearchScreen);

function App() {
  Config.default = "ja";
  Config.list = language_config;

  const { user } = useSelector((state: any) => state.userAuth);

  useLayoutEffect(() => {
    // Your layout effect logic here
  }, []);

  useLayoutEffect(() => {
    if (user?.id) {
      setAuthorizationToken(user.auth_token);
    }
  }, [user]);

  const theme = createTheme({
    typography: {
      fontFamily: "mplus-1c-regular",
    },
    palette: {
      primary: {
        main: colors.farm_green500,
        contrastText: "white",
      },
      secondary: {
        main: colors.farm_blue200,
        light: colors.farm_blue200,
        contrastText: "white",
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            userSelect: "text",
          },
        },
      },
    },
  });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Translator>
          <div className="App">
            <Router>
              <header>
                <Header />
              </header>
              <Routes>
                <Route path="/postview" element={<PostViewPage />} />
                <Route path="/adminhome" element={<AdminHome />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<IntroPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/user" element={<UserInfo />} />
                <Route path="/categories" element={<CategoryPage />} />
                <Route path="/detailsview" element={<DetailsView />} />
                <Route path="/facilitator" element={<FacilitatorPage />} />
                <Route path="/suggestion" element={<SuggestionPage />} />
                <Route path="/post" element={<PostCreatePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/myfarm" element={<MyFarmPage />} />
                <Route path="/search" element={<Search />} />
                <Route path="/posthistory" element={<PostHistoryScreen />} />
                <Route path="/votingseemore" element={<VotingSeeMore />} />
                <Route path="/navigator" element={<NavigatorLogin />} />
                <Route
                  path="/everyoneshistory"
                  element={<EveryOnesPostHistoryScreen />}
                />
                <Route
                  path="/commenthistory"
                  element={<CommentHistoryScreen />}
                />

                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NoPage />} />
              </Routes>
            </Router>
          </div>
        </Translator>
      </ThemeProvider>
    </AuthProvider>
  );
}

const NoPage = () => {
  return <h1>Page not found 404</h1>;
};

export default App;

declare global {
  interface Window {
    PortalAPP: { GetPersonalBasicInfo: any };
    UploadImage: any;
    getAccessToken: any;
    GetAccessToken: any;
    getPersonalBasicInfo: any;
    portalAppGetAccessTokenCallBack: any;
  }
}
