import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { colors } from "../../common/colors";

interface OptionsModalProps {
  open: boolean;
  onClose: () => void;
  onNext: () => void;
}

const PersonalInfoPopup: React.FC<OptionsModalProps> = ({
  open,
  onClose,
  onNext,
}) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <img
          alt=""
          style={{ width: 100 }}
          src={require("../../assets/images/logo.png")}
        />
        <br />
        <Typography>
          めぶくコミュニティは、より不正のない安全なコミュニティを目指している為、皆様に個人情報のご提供をお願いしております。
          下の「つぎへ」ボタンより画面が切り替わりますので、画面に従って進んでください。
        </Typography>
        <br />
        <Button onClick={() => onNext()} sx={buttonStyle}>
          つぎへ
        </Button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const buttonStyle = {
  color: colors.farm_blue200,
  fontWeight: "600",
};

export default PersonalInfoPopup;
