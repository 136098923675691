import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import appServices from "../../services/app-services/appServices";

const initialState: any = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  groups: [],
};

export const getGroups = createAsyncThunk(
  "groups",
  async (_, thunkAPI: any) => {
    try {
      return await appServices.getGroups(1);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const groupReducer = createSlice({
  name: "groups",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groups = action.payload;
      })
      .addCase(getGroups.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { reset } = groupReducer.actions;

export default groupReducer.reducer;
