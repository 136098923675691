import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { styles } from "../pages/styles";
import { colors } from "../common/colors";

interface Props {
  title: string;
  value: any;
  options: any[];
  error?: boolean;
  onChange(value: any): void;
  star?: boolean;
}

export default function DropDown({
  title,
  value,
  options,
  error,
  onChange,
  star = false,
}: Props) {
  return (
    <div>
      <FormControl
        sx={{
          marginTop: 2,
          width: "100%",
          justifyContent: "left",
          zIndex: 0,
        }}
        fullWidth
        error={error}
      >
        <InputLabel
          sx={{ color: "black", fontWeight: "600" }}
          id="simple-select-label"
        >
          {title}{" "}
          {star && <span style={{ color: colors.farm_red600 }}> *&nbsp;</span>}
        </InputLabel>
        <Select
          className=""
          labelId="simple-select-label"
          id="simple-select"
          sx={styles.dropDownSelected}
          value={value}
          label={title}
          defaultValue={value}
          onChange={onChange}
        >
          {options.map((item) => (
            <MenuItem
              key={item.value}
              style={styles.dropDownMenuItem}
              value={item.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>* fields are required.</FormHelperText>}
      </FormControl>
    </div>
  );
}
