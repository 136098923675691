import { Button, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DropDown from "../components/DropDown";
import "../App.css";
import { styles } from "./styles";
import LoadingModal from "../components/LoadingModal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { getGroups } from "../redux/Reducers/groupReducer";
import {
  getCategoryPosts,
  removeReportedCategoryPost,
} from "../redux/Reducers/postReducer";
import { T } from "react-translator-component";
import { getCategory } from "../redux/Reducers/categoriesReducer";
import { colors } from "../common/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import VerticalListP2 from "../components/lists/VerticalListP2";
import appServices from "../services/app-services/appServices";
import TabComponent from "../components/Phase2/TabComponent";

export default function CategoryListPage() {
  const [communityPost, setCommunityPosts] = useState<any>([]); //using now for all categories
  const [filteredPost, setFilteredPosts] = useState<any>([]);
  const { groups } = useSelector((state: any) => state.groups);
  const { categories } = useSelector((state: any) => state.categories);
  const [sortedCategories, setSortedCategories] = useState<any>([]);
  // const [current_page, setCurrentPage] = useState(0);
  const { categoryPosts, categoryPostsPagination, isLoadingCategoryPosts } =
    useSelector((state: any) => state.posts);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState("desc");
  const [loading, setLoading] = useState(false);
  // const DESC = [
  //   "気になること、知りたいこと、伝えたいことなど、自由に投稿してください。",
  //   "イベントや行事やお祭りなど、大きな集まりから小さな集まりまで自由に投稿してください。",
  //   "行ってみた、食べてみた、体験してきた、などのレポートを自由に投稿してください。",
  // ];
  const dispatch = useAppDispatch();
  useEffect(() => {
    // dispatch(resetCategoryPosts());
    apiCalls();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (categoryPostsPagination?.current_page)
  //     setCurrentPage(categoryPostsPagination?.current_page);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categoryPostsPagination]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const apiCalls = async () => {
    setLoader(true);
    await dispatch(getGroups());
    await dispatch(getCategory());
    let data = { categoryId: "", page: 1, order: order };
    await dispatch(getCategoryPosts(data));
    setLoader(false);
  };
  useEffect(() => {
    if (categoryPostsPagination?.current_page > 1) {
      const newItems = [...communityPost, ...categoryPosts];
      console.log(newItems);
      if (newItems) setCommunityPosts(newItems);
    } else {
      setCommunityPosts(categoryPosts);
      // setFilteredPosts(categoryPosts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryPosts]);

  useEffect(() => {
    if (categories?.length > 0) {
      const temp = [...categories].sort(
        (a: { id: number }, b: { id: number }) =>
          a.id < b.id ? -1 : a.id > b.id ? 1 : 0
      );
      setSortedCategories(temp);
      // setSelectedCategory(temp[0]);
    }
  }, [categories]);

  const [category, setSelectedCategory] = useState<any>({
    value: "",
    name: sortedCategories[0]?.name,
  });
  const [group, setSelectedGroup] = useState<any>({
    value: "",
    name: "Group",
  });
  const [sort, setSort] = useState<any>({
    value: "",
    name: "Sort",
  });

  const handleSelectGroup = (event: SelectChangeEvent) => {
    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Group" : event.target.name,
    };
    setSelectedGroup(v);
  };
  const handleSelectCategory = (c: any) => {
    console.log(c);
    // const v = {
    //   value: event.target.value,
    //   name: event.target.value === "" ? "Category" : event.target.name,
    // };
    if (c !== 0) {
      const v = sortedCategories[c - 1];
      console.log(v);
      setSelectedCategory(v);
      setCommunityPosts([]);
      dispatch(getCategoryPosts({ categoryId: v.value, page: 1 }));
    } else {
      dispatch(getCategoryPosts({ categoryId: "", page: 1 }));
    }
  };
  const [likeLoading, setLikeLoading] = useState(false);

  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        let data = {
          categoryId: category.id ? category.id : "",
          page: categoryPostsPagination.current_page,
          order: order,
        };
        await dispatch(getCategoryPosts(data));

        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    } finally {
    }

    // console.log('like')
  };
  const handleSort = (event: SelectChangeEvent) => {
    console.log(event.target);

    const v = {
      value: event.target.value,
      name: event.target.value === "" ? "Sort" : event.target.name,
    };
    if (v.value.toString() === "2") {
      setOrder("asc");

      let data = { categoryId: "", page: 1, order: "asc" };
      dispatch(getCategoryPosts(data));
    } else if (v.value.toString() === "1") {
      setOrder("desc");
      let data = { categoryId: "", page: 1, order: "desc" };
      dispatch(getCategoryPosts(data));
    }
    setSort(v);
  };

  const handleReport = async (post_id: number, status: any) => {
    try {
      setLoading(true);
      const response = await appServices.acceptRejectPost(post_id, status);
      console.log(response, "responseeeeeee");
      setLoading(false);
      setTimeout(() => {
        dispatch(removeReportedCategoryPost(post_id));
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  const renderList = useMemo(() => {
    if (filteredPost?.length === 0) return;
    console.log(sort.value);
    if (sort.value === 4) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.comments?.length > b.comments?.length ? 1 : -1
      );

      return (
        <VerticalListP2
          handleReport={handleReport}
          page={categoryPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (sort.value === 3) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.comments?.length < b.comments?.length ? 1 : -1
      );

      return (
        <VerticalListP2
          handleReport={handleReport}
          page={categoryPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (sort.value === 2) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.id > b.id ? 1 : -1
      );

      return (
        <VerticalListP2
          handleReport={handleReport}
          page={categoryPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else if (sort.value === 1) {
      const temp = [...filteredPost].sort((a: any, b: any) =>
        a.id < b.id ? 1 : -1
      );
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={categoryPostsPagination?.current_page}
          order={order}
          items={temp}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    } else {
      return (
        <VerticalListP2
          handleReport={handleReport}
          page={categoryPostsPagination?.current_page}
          order={order}
          items={filteredPost}
          onLike={handleThumbUp}
          onUnLike={handleThumbUp}
          likeLoading={likeLoading}
        />
      );
    }
    // eslint-disable-next-line
  }, [filteredPost, sort]);

  useEffect(() => {
    if (group.value !== "") {
      const filtered = communityPost.filter(
        (item: any) => item?.group?.id === group.value
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(communityPost);
    }
  }, [group, communityPost]);

  const loadMore = () => {
    if (categoryPostsPagination?.next_page > 0) {
      let data = {
        categoryId: category?.value,
        page: categoryPostsPagination?.next_page,
        order: order,
      };
      dispatch(getCategoryPosts(data));
    }
  };

  const navigate = useNavigate();
  const gotoPost = () => {
    navigate("/post");
  };

  return (
    <div style={{ ...styles.communityContainer, marginTop: 80 }}>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 16,
          display: "flex",
          marginBottom: 14,
        }}
      >
        <Button
          onClick={() => gotoPost()}
          // disabled={disabled}
          style={{
            alignSelf: "center",
            borderRadius: 20,
            backgroundColor: colors.farm_blue200,
          }}
          size={"large"}
          variant="contained"
          endIcon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          <span style={{ minWidth: 200 }}>{T("Post")}</span>
        </Button>
      </div>
      <TabComponent onChange={handleSelectCategory} />
      {/* <DropDown
        title={T("Category")}
        value={category.value}
        options={sortedCategories}
        onChange={handleSelectCategory}
      />
      <p
        style={{
          ...styles.communiTitleDescrip,
          textAlign: "center",
          marginBottom: 0,
        }}
      >
        {DESC[category.value - 1]}
      </p> */}

      <DropDown
        title={T("Group")}
        value={group.value}
        options={
          groups?.length > 0
            ? [{ value: "", name: T("Clear_Filter") }, ...groups]
            : []
        }
        onChange={handleSelectGroup}
      />
      <DropDown
        title={T("Sort")}
        value={sort.value}
        options={[
          { value: 1, name: T("New") },
          { value: 2, name: T("Old") },
          { value: 3, name: "コメントが多い順" },
          { value: 4, name: "コメントが少ない順" },
        ]}
        onChange={handleSort}
      />

      <div>{renderList}</div>

      <LoadingModal
        visible={loader || isLoadingCategoryPosts}
        handleClose={() => console.log("here")}
      />
      <LoadingModal visible={loading} handleClose={() => console.log("here")} />
      {categoryPostsPagination?.next_page > 0 && (
        <Stack
          sx={{ width: "100%", color: "grey.500", justifyContent: "center" }}
          spacing={2}
        >
          <Button onClick={loadMore} size="small">
            {T("Load_More")}
          </Button>
        </Stack>
      )}
    </div>
  );
}
