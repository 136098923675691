import React, { useEffect, useMemo } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ComposedChart,
  Rectangle,
  Legend,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";
import { colors } from "../../common/colors";
import { colors as mui_color } from "@mui/material";
import "./DashboardComponent.css"; // Import the CSS file
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useAppDispatch } from "../../redux/store";
import { getPostsInterval } from "../../redux/Reducers/dashboardReducer";
import { useSelector } from "react-redux";

interface Props {}

const DashboardComponent = (props: Props) => {
  const { postsInterval } = useSelector((state: any) => state.dashboard);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPostsInterval());
  }, [dispatch]);

  useEffect(() => {
    console.log(postsInterval);
  }, [postsInterval]);

  const users = {
    weekly: [
      { name: "1", allUsers: 3000, tempUsers: 2400, registeredUsers: 600 },
      { name: "2", allUsers: 3500, tempUsers: 2000, registeredUsers: 1500 },
      { name: "3", allUsers: 4000, tempUsers: 800, registeredUsers: 3200 },
      { name: "4", allUsers: 4500, tempUsers: 3500, registeredUsers: 1000 },
      { name: "5", allUsers: 5890, tempUsers: 4000, registeredUsers: 1890 },
    ],
    monthly: [
      { name: "3", allUsers: 4000, tempUsers: 800, registeredUsers: 3200 },
      { name: "4", allUsers: 4500, tempUsers: 3500, registeredUsers: 1000 },
      { name: "5", allUsers: 5890, tempUsers: 4000, registeredUsers: 1890 },
      { name: "1", allUsers: 3000, tempUsers: 2400, registeredUsers: 600 },
      { name: "2", allUsers: 3500, tempUsers: 2000, registeredUsers: 1500 },
    ],

    daily: [
      { name: "3", allUsers: 4000, tempUsers: 800, registeredUsers: 3200 },
      { name: "4", allUsers: 4500, tempUsers: 3500, registeredUsers: 1000 },
      { name: "1", allUsers: 3000, tempUsers: 2400, registeredUsers: 600 },
      { name: "2", allUsers: 3500, tempUsers: 2000, registeredUsers: 1500 },

      { name: "5", allUsers: 5890, tempUsers: 4000, registeredUsers: 1890 },
    ],
  };

  const votes = {
    weekly: [
      { name: "1", value: 3000 },

      { name: "5", value: 5890 },
      { name: "2", value: 3500 },
      { name: "3", value: 4000 },
      { name: "4", value: 4500 },
    ],

    monthly: [
      { name: "3", value: 4000 },

      { name: "5", value: 5890 },
      { name: "1", value: 3000 },
      { name: "2", value: 3500 },
      { name: "4", value: 4500 },
    ],

    daily: [
      { name: "3", value: 4000 },
      { name: "4", value: 4500 },
      { name: "5", value: 5890 },

      { name: "1", value: 3000 },
      { name: "2", value: 3500 },
    ],
  };

  const UserActions = {
    weekly: [
      { name: "Inquire only", value: 400 },
      { name: "Thumbs up", value: 300 },
      { name: "Vote", value: 300 },
      { name: "Comment", value: 200 },
      { name: "Post", value: 200 },
    ],
    monthly: [
      { name: "Inquire only", value: 400 },
      { name: "Thumbs up", value: 300 },
      { name: "Vote", value: 300 },
      { name: "Comment", value: 200 },
      { name: "Post", value: 200 },
    ],
    daily: [
      { name: "Inquire only", value: 400 },
      { name: "Thumbs up", value: 300 },
      { name: "Vote", value: 300 },
      { name: "Comment", value: 200 },
      { name: "Post", value: 200 },
    ],
  };

  const typeOfUsers = {
    weekly: [
      { name: "Registered", value: 600 },
      { name: "Temp ID", value: 400 },
      { name: "Temp ID MNC", value: 200 },
    ],
    monthly: [
      { name: "Registered", value: 800 },
      { name: "Temp ID", value: 300 },
      { name: "Temp ID MNC", value: 200 },
    ],
    daily: [
      { name: "Registered", value: 700 },
      { name: "Temp ID", value: 300 },
      { name: "Temp ID MNC", value: 100 },
    ],
  };
  const deletedData = {
    weekly: [
      { name: "Not Deleted", value: 800 },
      { name: "Comments deleted", value: 300 },
      { name: "Posts deleted", value: 200 },
    ],
    monthly: [
      { name: "Not Deleted", value: 800 },
      { name: "Comments deleted", value: 400 },
      { name: "Posts deleted", value: 300 },
    ],
    daily: [
      { name: "Not Deleted", value: 500 },
      { name: "Comments deleted", value: 300 },
      { name: "Posts deleted", value: 200 },
    ],
  };

  const [period, setPeriod] = React.useState("Daily");
  const [periodUsers, setPeriodUsers] = React.useState("DailyUsers");

  const handle = (value: any) => {
    setPeriod(value.target.value);
  };
  const handleUsers = (value: any) => {
    setPeriodUsers(value.target.value);
  };

  const CustomBar = (props: any) => {
    const { fill, x, y, width, height } = props;
    return (
      <Rectangle
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
        radius={[4, 4, 0, 0]} // [top-left, top-right, bottom-right, bottom-left]
      />
    );
  };

  const CustomBarLeft = (props: any) => {
    const { fill, x, y, width, height } = props;
    return (
      <Rectangle
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
        radius={[4, 0, 0, 0]} // [top-left, top-right, bottom-right, bottom-left]
      />
    );
  };
  const CustomBarRight = (props: any) => {
    const { fill, x, y, width, height } = props;
    return (
      <Rectangle
        fill={fill}
        x={x}
        y={y}
        width={width}
        height={height}
        radius={[0, 4, 0, 0]} // [top-left, top-right, bottom-right, bottom-left]
      />
    );
  };

  const COLORS = [
    colors.farm_blue200,
    mui_color.red["A200"],
    mui_color.purple["400"],
    mui_color.green["400"],
    mui_color.blue["200"],
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={12}
      >
        {value}
      </text>
    );
  };

  const CustomPieChart = ({ data }: any) => {
    // Select data based on the period

    const selectedData = useMemo(() => {
      return periodUsers === "WeeklyUsers"
        ? data.weekly
        : periodUsers === "MonthlyUsers"
        ? data.monthly
        : data.daily;

      // eslint-disable-next-line
    }, [data, periodUsers]);

    return (
      <PieChart width={300} height={300}>
        <Legend />
        <Pie
          data={selectedData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {selectedData.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  };

  return (
    <div>
      <h1>Overall (overall accumulation since the release)</h1>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={period}
          onChange={handle}
        >
          <FormControlLabel value="Daily" control={<Radio />} label="Daily" />
          <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
          <FormControlLabel
            value="Monthly"
            control={<Radio />}
            label="Monthly"
          />
        </RadioGroup>
      </FormControl>

      <div className="chart-container">
        <div className="chart-item">
          <h6 style={{ paddingLeft: 40 }}> Users</h6>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={
                period === "Weekly"
                  ? users?.weekly
                  : period === "Monthly"
                  ? users?.monthly
                  : users?.daily
              }
              margin={{ top: 20, left: 20, bottom: 5 }}
            >
              <XAxis />
              <YAxis />
              {/* <Tooltip /> */}
              <Legend />
              <Tooltip />
              <Bar
                shape={<CustomBarLeft />}
                dataKey="allUsers"
                fill={colors.farm_blue200}
                barSize={30}
              />
              <Bar
                stackId={"a"}
                dataKey="registeredUsers"
                fill={colors.farm_green200}
                barSize={10}
              />
              <Bar
                shape={<CustomBarRight />}
                stackId={"a"}
                dataKey="tempUsers"
                fill={colors.farm_red600}
                barSize={10}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-item">
          <h6 style={{ paddingLeft: 40 }}>Post / Post with Votes</h6>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              data={
                period === "Weekly"
                  ? postsInterval?.posts?.weekly
                  : period === "Monthly"
                  ? postsInterval?.posts?.monthly
                  : postsInterval?.posts?.daily
              }
              margin={{ top: 20, left: 20, bottom: 5 }}
            >
              <XAxis />
              <YAxis />
              <Tooltip />
              <Bar
                shape={<CustomBar />}
                dataKey="post"
                fill={colors.farm_green200}
                barSize={30}
              />
              <Bar
                shape={<CustomBar />}
                dataKey="post_votes"
                fill={colors.farm_blue200}
                barSize={30}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-item">
          <h6 style={{ paddingLeft: 40 }}>Comments</h6>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              data={
                period === "Weekly"
                  ? postsInterval?.comments?.weekly
                  : period === "Monthly"
                  ? postsInterval?.comments?.monthly
                  : postsInterval?.comments?.daily
              }
              margin={{ top: 20, left: 20, bottom: 5 }}
            >
              <XAxis />
              <YAxis />
              <Tooltip />
              <Bar
                shape={<CustomBar />}
                dataKey="value"
                fill={mui_color.cyan[300]}
                barSize={30}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-item">
          <h6 style={{ paddingLeft: 40 }}>Votes</h6>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              data={
                period === "Weekly"
                  ? postsInterval?.weekly
                  : postsInterval?.period === "Monthly"
                  ? votes?.monthly
                  : postsInterval?.votes?.daily
              }
              margin={{ top: 20, left: 20, bottom: 5 }}
            >
              <XAxis />
              <YAxis />
              <Tooltip />
              <Bar
                shape={<CustomBar />}
                dataKey="value"
                fill={mui_color.cyan[100]}
                barSize={30}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <br />
      <h1>User action and trend（Obtained from logs）</h1>
      <br />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={periodUsers}
          onChange={handleUsers}
        >
          <FormControlLabel
            value="DailyUsers"
            control={<Radio />}
            label="Daily"
          />
          <FormControlLabel
            value="WeeklyUsers"
            control={<Radio />}
            label="Weekly"
          />
          <FormControlLabel
            value="MonthlyUsers"
            control={<Radio />}
            label="Monthly"
          />
        </RadioGroup>
      </FormControl>
      <div className="chart-container">
        <div className="chart-item">
          <h6 style={{ paddingLeft: 40 }}>User actions</h6>
          <ResponsiveContainer width="100%" height={300}>
            <CustomPieChart data={UserActions} />
          </ResponsiveContainer>
        </div>
        <div className="chart-item">
          <h6 style={{ paddingLeft: 40 }}>Type of users</h6>
          <ResponsiveContainer width="100%" height={300}>
            <CustomPieChart data={typeOfUsers} />
          </ResponsiveContainer>
        </div>
        <div className="chart-item">
          <h6 style={{ paddingLeft: 40 }}>Number of deleted posts</h6>
          <ResponsiveContainer width="100%" height={300}>
            <CustomPieChart data={deletedData} />
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
