import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import appServices from "../../services/app-services/appServices";
import { useSelector } from "react-redux";
import VerticalListP2 from "../../components/lists/VerticalListP2";
import LoadingModal from "../../components/LoadingModal";
import { Button, Stack, Typography } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import appServices from "../../services/app-services/appServices";
import { T } from "react-translator-component";

export default function PostHistory() {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.userAuth);
  const { isLoadingPrivatePosts } = useSelector((state: any) => state.posts);
  const [privatePosts, setPrivatePosts] = useState<any>([]);
  const [privatePagination, setPrivatePostsPagination] = useState<any>({});

  const [loading, setLoading] = useState(false);
  useEffect(() => {}, []);
  useEffect(() => {
    if (user?.id) {
      // apiCalls();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    console.log(privatePosts);
  }, [privatePosts]);

  useEffect(() => {
    if (user?.id) {
      apiCalls();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const apiCalls = async () => {
    setLoading(true);
    try {
      const posts = await appServices.getApprovedPrivatePosts(1, "desc");

      const filteredPosts = posts.posts.filter((item: any) => {
        // Filter posts based on the conditions
        return (
          (item.user.id !== user.id && item.status === "Approved") ||
          item.user.id === user.id
        );
      });

      setPrivatePosts(filteredPosts);
      setPrivatePostsPagination(posts.pagination);
      setLoading(false);
    } catch (error: any) {
      console.log("er", error.response);
      if (error.response.status === 403) {
        // logout();
      }
      setLoading(false);
    }
  };

  const handleMore = async () => {
    if (privatePagination?.next_page > 0) {
      try {
        setLoading(true);
        const posts = await appServices.getApprovedPrivatePosts(
          privatePagination?.next_page,
          "desc"
        );
        const filteredPosts = posts.posts.filter((item: any) => {
          // Filter posts based on the conditions
          return (
            (item.user.id !== user.id && item.status === "Approved") ||
            item.user.id === user.id
          );
        });
        setLoading(false);
        const newItems = [...privatePosts, ...filteredPosts];
        setPrivatePosts(newItems);
        setPrivatePostsPagination(posts.pagination);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const removeObjectById = (id: any) => {
    setPrivatePosts((prevObjects: any) =>
      prevObjects.filter((obj: any) => obj.id !== id)
    );
  };

  const handleReport = async (post_id: number, status: any) => {
    try {
      setLoading(true);
      const response = await appServices.acceptRejectPost(post_id, status);
      console.log(response, "responseeeeeee");
      setLoading(false);
      setTimeout(() => {
        removeObjectById(post_id);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  const [likeLoading, setLikeLoading] = useState(false);

  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        handleLikeToggle(item.id, action === "like" ? false : true);
        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    } finally {
      // setReSearch(true);
    }

    // console.log('like')
  };
  const handleLikeToggle = (postId: any, isLiked: boolean) => {
    setPrivatePosts((prevPosts: any) =>
      prevPosts.map((post: any) => {
        if (post.id === postId) {
          // Update the post's liked_by_me and likes_count based on the current state
          return {
            ...post,
            liked_by_me: !isLiked, // Toggle the liked_by_me value
            likes_count: isLiked ? post.likes_count - 1 : post.likes_count + 1, // Increase or decrease the likes_count
          };
        }
        return post;
      })
    );
  };

  return (
    <div>
      <div style={{ height: "100px" }}></div>
      <div style={{ marginLeft: 8 }} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon />
      </div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"center"}
        spacing={2}
      >
        <DriveFileRenameOutlineIcon />
        <Typography style={{}}>プライベート投稿</Typography>
      </Stack>
      <div
        style={{
          width: "95%",
          height: 3,
          backgroundColor: "black",
          margin: 4,
        }}
      ></div>
      <LoadingModal
        visible={isLoadingPrivatePosts}
        handleClose={() => console.log("here")}
      />
      <LoadingModal visible={loading} handleClose={() => console.log("here")} />

      <VerticalListP2
        handleReport={handleReport}
        page={"1"}
        order={"order"}
        items={privatePosts}
        showStatus
        onLike={handleThumbUp}
        onUnLike={handleThumbUp}
        likeLoading={likeLoading}
      />
      {privatePagination?.next_page > 0 && (
        <Stack
          sx={{
            width: "100%",
            color: "grey.500",
            justifyContent: "center",
          }}
          spacing={2}
        >
          <Button onClick={handleMore} size="small">
            {T("Load_More")}
          </Button>
        </Stack>
      )}
    </div>
  );
}
