export const language_config={
    en: {
      text: 'English',
      icon: require('../../assets/images/language/us.png'),
      file: require('./en.js')
    },
    ja: {
      text: 'Japanese',
      icon:  require('../../assets/images/language/ja.png'),
      file: require('./ja.js')
    }
  }