/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Alert, Snackbar, TextField } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import clogo from "../assets/sliderimages/community_logo.png";
import loginimg4 from "../assets/images/loginimg4.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "./styles";
import { useAppDispatch } from "../redux/store";
// import { loginAdmin } from "../redux/Reducers/adminAuthReducer";
import { useSelector } from "react-redux";
import LoadingModal from "../components/LoadingModal";
import { useNavigate } from "react-router-dom";
import setAuthorizationToken from "../services/axios-util/set-authorization-token";
import userAuth from "../util/auth-util";
import { loginAdmin, reset } from "../redux/Reducers/adminAuthReducer";
import setAuthToken from "../services/axios-util/set-auth-token";
// import setAuthToken from "../services/axios-util/set-auth-token";

const LoginForm = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [snake, setSnake] = useState("");
  const { isLoading, admin, message } = useSelector(
    (state: any) => state.adminAuth
  );
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // navigate("/adminhome");

  const handleLogin = () => {
    if (email.trim() !== "" && password.trim() !== "") {
      const body = { email: email, password: password };
      dispatch(loginAdmin(body));
    } else {
      setSnake("Email and Password is required!");
    }

    // navigate("/adminhome");
  };

  useEffect(() => {
    if (message) {
      setSnake(message);
      setTimeout(() => {
        dispatch(reset());
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  useEffect(() => {
    if (admin?.auth_token) {
      navigate("/adminhome");
      userAuth.loginUser({ ...admin, isAdmin: true });
      setAuthorizationToken(admin?.auth_token);
    } else {
      setAuthToken("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, admin]);

  // const login = () => {
  //   authService.loginChatgpt();
  // };

  // useEffect(() => {
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <React.Fragment>
      <LoadingModal
        visible={isLoading}
        handleClose={() => console.log("close loader")}
      />
      <div className="row  py-5 row-cols-1 row-cols-lg-2">
        <div
          className="feature col d-none d-lg-block py-5"
          style={styles.loginUserContainer}
        >
          <div style={styles.loginUsetrLeftSec}>
            <img
              src={loginimg4}
              alt="BigCo Inc. logo"
              style={styles.loginUserImg}
            />
          </div>
        </div>

        <div className="feature col-12" style={{}}>
          <div
            className="col-12"
            style={{ ...styles.loginUserRightSec, flexDirection: "column" }}
          >
            <img
              src={clogo}
              alt="BigCo Inc. logo"
              style={styles.loginUserCLogo}
            />
            <span style={{ ...styles.tableTitleUser, color: "#313131" }}>
              管理者ログイン
            </span>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                minWidth: 300,
                alignSelf: "center",
              }}
            >
              <span style={styles.loginFieldTitle}>ユーザー名</span>
              <TextField
                placeholder="Eメール"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
                style={styles.loginUserFiledTxt}
              />
            </div>
            <div
              style={{ ...styles.loginUserPassField, flexDirection: "column" }}
            >
              <span style={styles.loginUserPassFieldTxt}>パスワード</span>
              <TextField
                placeholder="パスワード"
                type="password"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                style={styles.loginUserFiledTxt}
              />
            </div>
            <div style={{ ...styles.loginuserForgotSec, flexDirection: "row" }}>
              <span style={{ ...styles.loginUserForgotSecTxt }}>
                パスワードをお忘れですか！
              </span>
            </div>

            <Button
              onClick={() => handleLogin()}
              style={styles.loginUserLoginBtn}
              size={"large"}
              variant="contained"
            >
              <span style={styles.loginUserLoginBtnTxt}>ログイン</span>
            </Button>
            {/* <span style={{ fontSize: 16, color: "gray", alignSelf: "center" }}>
              Create admin user
            </span> */}
          </div>
        </div>
        <Snackbar
          open={snake !== ""}
          autoHideDuration={3000}
          sx={{ width: "94%" }}
          onClose={() => setSnake("")}
        >
          <Alert
            onClose={() => setSnake("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snake}
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
