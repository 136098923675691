import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import profile from "../assets/images/profile_img.png";
import moment from "moment";
import { colors } from "../common/colors";
interface Props {
  items: any;
  onClickItem({ item }: any): void | undefined;
}
export default function ShowSuggestionsList({ items, onClickItem }: Props) {
  return (
    <div style={{ margin: 10, wordWrap: "break-word" }}>
      {items.map((item: any) => (
        <ListItemButton component="a">
          <ListItem alignItems="flex-start" style={{ wordWrap: "break-word" }}>
            <ListItemAvatar>
              <Avatar
                alt="Remy Sharp"
                src={
                  item?.user?.image !== null
                    ? "data:image/jpeg;base64," + item?.user?.image
                    : profile
                }
              />
            </ListItemAvatar>
            <ListItemText
              sx={{ color: colors.farm_blue200, wordWrap: "break-word" }}
              style={{ maxWidth: "fit-content", wordBreak: "break-all" }}
              primary={item?.user?.nickname}
              secondary={
                <div>
                  <p
                    style={{
                      display: "inline",
                      wordWrap: "break-word",
                      maxWidth: 600,
                    }}
                  >
                    {item?.title}
                  </p>
                  <Typography sx={{ fontSize: 10, color: colors.farm_gray200 }}>
                    {moment(item?.created_at).format("MMMM Do YYYY, h:mm a")}
                  </Typography>
                </div>
              }
            />
          </ListItem>
        </ListItemButton>
      ))}
    </div>
  );
}
