import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/app-services/auth-service";

const initialState: any = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
  admin: {},
};

export const loginAdmin = createAsyncThunk<any, any>(
  "admin/login",
  async (body, thunkAPI) => {
    try {
      const res = await authService.loginAdmin(body);
      console.log(res);
      return res;
    } catch (error: any) {
      console.log(error);
      const message =
        (error.response &&
          error.response?.data &&
          error.response?.data?.message[0]) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const adminAuthReducer = createSlice({
  name: "admin",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.admin = {};
    },
    resetMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.isLoading = true;
        state.message = "";
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.admin = {
          ...action.payload?.data?.user,
          auth_token: action?.payload?.headers["session_id"]
            ? action?.payload?.headers["session_id"]
            : action?.payload?.headers["auth_token"],
        };
        state.message = "";
      })
      .addCase(loginAdmin.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.admin = {};
      });
  },
});

export const { reset, resetMessage } = adminAuthReducer.actions;

export default adminAuthReducer.reducer;
