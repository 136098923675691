import React from "react";
import {
  Container,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ChatIcon from "@mui/icons-material/Chat";
import HowToVoteIcon from "@mui/icons-material/HowToVote";

interface Props {
  topPosts: any;
  bgColor: string;
}

const UserActionTop = ({ topPosts, bgColor = "#FFFFFF" }: Props) => {
  // const { topPosts } = useSelector((state: any) => state.posts);
  // Sample data

  return (
    <Container style={{ padding: 0, marginTop: 20 }}>
      <Paper style={{ padding: 2, backgroundColor: bgColor }}>
        <Typography textAlign={"center"} variant="h6" gutterBottom>
          ユーザーアクション TOP 10
        </Typography>
        <div
          style={{
            borderBottomWidth: 2,
            borderBottomStyle: "dashed",
            borderBottomColor: "#FC98BE",
            marginBottom: 16,
          }}
        ></div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ backgroundColor: "white" }}>
                <IconButton style={{ backgroundColor: "#FC98BE" }}>
                  <ThumbUpIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton style={{ backgroundColor: "#87BBF2" }}>
                  <ChatIcon />
                </IconButton>
              </TableCell>
              <TableCell style={{ backgroundColor: "white" }}>
                <IconButton style={{ backgroundColor: "#FFD483" }}>
                  <HowToVoteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topPosts?.map((row: any, index: any) => (
              <TableRow key={index}>
                <TableCell style={{ textAlign: "center" }}>
                  {row.title}
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", backgroundColor: "white" }}
                >
                  {row.likes_count}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row.comments_count}
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", backgroundColor: "white" }}
                >
                  {row.votes_count}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Container>
  );
};

export default UserActionTop;
