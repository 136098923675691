import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  TextField,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import Paper from "@mui/material/Paper";
// import { AsyncImage } from "loadable-image";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../common/colors";
import EditIcon from "@mui/icons-material/Edit";
import profile from "../assets/images/profile_img.png";
import {
  faClose,
  faThumbsUp as thumbup,
} from "@fortawesome/free-solid-svg-icons";
import { faCommentDots, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { colors as farmColor } from "../common/colors";
import VoteIcon from "../assets/images/svg/vote_black.svg";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";
import { styles } from "./styles";
import { T } from "react-translator-component";
import appServices from "../services/app-services/appServices";
import LoadingModal from "../components/LoadingModal";
import { useSelector } from "react-redux";
import moment from "moment";
// import appServices from "../services/app-services/appServices";

export default function DetailsViewPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useSelector((state: any) => state.userAuth);
  const [postState, setPostState] = useState(state);
  const [thumbsUp, setThumbsUp] = useState(false);
  const [viewStatus, setViewStatus] = useState(false);
  const [reportConfirmation, setReportConfirmation] = React.useState(false);
  const [voteOptions, setVoteOptions] = useState<any>([]);
  const [comment, setComment] = React.useState(false);
  const [likeLoading, setLikeLoading] = React.useState(false);
  const [commentArea, setCommentArea] = React.useState(false);
  const [voteSection, setVoteSection] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [commentLike, setCommentLike] = useState<any>("");
  const [commentText, setCommentText] = useState<string>("");
  // const [snake, setSnake] = useState(false);
  const [snakeText, setSnakeText] = useState("");
  const [editCurrentComment, setCurrentEditComment] = useState<any>();
  // const [likeCount, setLikeCount] = useState<number>(0);
  // const [postComments, setPostComments] = useState([]);
  // const [postData, setPostData] = React.useState(null);

  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  // const onBlur = () => setFocused(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: colors.farm_green500,
    },
  }));

  useEffect(() => {
    setThumbsUp(postState?.liked_by_me);
    const votes = calculatePercentage(postState?.vote_options);
    setVoteOptions(votes);
  }, [postState]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickOpen = (vote: any) => {
    console.log(vote);
    const dateToCheck = moment(postState?.expiry_date);
    const todayDate = moment();
    // console.log("dateToCheck", postState?.expiry_date, dateToCheck);
    // console.log("todayDate", todayDate);
    if (dateToCheck.isSameOrAfter(todayDate, "day")) {
      console.log("The date is the same as or after today's date.");
      if (user?.id_kind === "2") {
      } else {
        if (postState?.user?.id === user?.id) return;
        if (postState?.voted_info?.voted_by_me) return;
        console.log(postState?.voted_info?.voted_by_me);
        // setVoteOption(vote);
        handleVoteAnonymouslyNo(vote);
        // setOpen(true);
      }
    } else {
      console.log("The date is before today's date.");
    }
  };

  useEffect(() => {
    if (state?.id) {
      try {
        appServices.addView(state.id);
      } catch (error) {}

      // getSinglePost();
    }
    // eslint-disable-next-line
  }, [state]);

  const getSinglePost = async () => {
    const post = await appServices.getSinglePost(state.id);

    setPostState(post.post);
  };
  // console.log(postState.post_likes,'post_likessssssssssssssssssss')
  const voteaddedtext = T("Your_vote_added");

  const handleVoteAnonymouslyNo = async (vote: any) => {
    setCommentText("");
    try {
      setLoading(true);
      const addvote = await appServices.createVote(vote.id, false);
      try {
        getSinglePost();
      } catch (error) {}
      console.log(addvote);
      setSnakeText(voteaddedtext);
      setViewStatus(true);
      setLoading(false);

      if (postState?.enable_comments) {
        // setComment(true);
        // setCommentAnonymous(false);
      } else {
        setSnakeText(voteaddedtext);
        try {
          getSinglePost();
        } catch (error) {}
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const like = async () => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(state.id, "like");

      try {
        await getSinglePost();
        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    }
    // console.log('like')
  };

  const unlike = async () => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(state.id, "delete_like");

      try {
        await getSinglePost();
        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    }
    console.log("unlike");
  };

  // const handleThumbUp = () => {
  //   if (thumbsUp) {
  //     unlike();
  //     setThumbsUp(!thumbsUp);
  //   } else {
  //     like();
  //     setThumbsUp(!thumbsUp);
  //   }
  // };
  const handleThumbUp = async () => {
    setLikeLoading(true);

    try {
      if (thumbsUp) {
        await unlike();
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like();
        // setLikeCount(likeCount + 1); // Increase the like count
      }
      setThumbsUp(!thumbsUp);
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const calculatePercentage = (options: any[]) => {
    // Step 1: Calculate total votes
    const totalVotes = options.reduce(
      (total: any, option: { votes_count: any }) => total + option.votes_count,
      0
    );

    // Step 2: Calculate and add percentage to each option
    return options.map((option) => {
      const percentage =
        totalVotes > 0 ? (option.votes_count / totalVotes) * 100 : 0;
      return { ...option, percentage: percentage.toFixed(0) };
    });
  };

  const handleCommentButtons = (yesNo: boolean) => {
    setCommentText("");
    setComment(false);
    setCommentArea(yesNo);
  };
  const focusCommentArea = (input: any) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 500);
    }
  };

  const Yourcommentaddedtext = T("Your_comment_added");

  const postComment = async () => {
    if (editCurrentComment) {
      editComment();
    } else {
      if (commentText !== "") {
        try {
          setLoading(true);
          const comment = await appServices.createComment(
            commentText,
            postState?.id,
            null,
            "0",
            []
          );
          setLoading(false);
          setCommentArea(false);
          setSnakeText(Yourcommentaddedtext);

          console.log(comment);
          try {
            getSinglePost();
          } catch (error) {}
        } catch (error) {
          setLoading(false);
        }
      } else {
        console.log("no comment");
      }
    }
  };

  const editComment = async () => {
    try {
      setLoading(true);
      const comment = await appServices.editComment(
        editCurrentComment?.id,
        commentText,
        postState?.id,
        editCurrentComment.is_anonymous
      );
      try {
        await getSinglePost();
      } catch (error) {}
      setLoading(false);

      setSnakeText(Yourcommentaddedtext);
      setCommentArea(false);
      setCurrentEditComment(undefined);
      setCommentText("");

      console.log(comment);
    } catch (error) {
      try {
        await getSinglePost();
        setCommentArea(false);
        setCurrentEditComment(undefined);
        setCommentText("");
      } catch (error) {}
      console.log(error);
      setLoading(false);
    }
  };

  const editCurrentCmt = (comment: any) => {
    setCurrentEditComment(comment);
    setCommentText(comment.content);
    setCommentArea(true);
  };

  console.log(postState, "stateeeeeee");

  const likeComment = async (isLiked: boolean, comment_id: any) => {
    if (!isLiked) {
      try {
        setCommentLike(comment_id);
        await appServices.likeUnlikeComment(comment_id, "like");
        await getSinglePost();
        setCommentLike("");
      } catch (error) {
        setCommentLike("");
      }
    } else {
      try {
        setCommentLike(comment_id);
        await appServices.likeUnlikeComment(comment_id, "delete_like");
        await getSinglePost();
        setCommentLike("");
      } catch (error) {
        setCommentLike("");
      }
    }
  };

  // const renderTextWithLinks = (text: string) => {
  //   // Regular expression to find URLs in the text
  //   const urlRegex = /(https?:\/\/)?(www\.[^\s]+|[^\s]+\.[^\s]+)/g;
  //   // Replace URLs with anchor tags
  //   const replacedText = text.replace(urlRegex, (url) => {
  //     // Prepend "https://" if the URL doesn't have a protocol specified
  //     if (!/^https?:\/\//i.test(url)) {
  //       url = "https://" + url;
  //     }
  //     return `<a href="${url}" >${url} </a>`;
  //   });

  //   // Replace newline characters with HTML line breaks
  //   return replacedText.replace(/\n/g, "<br>");
  // };
  const renderTextWithLinks = (text: string) => {
    // Regular expression to find URLs in the text
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})[\S]*\b/g;

    // Replace URLs with anchor tags
    const replacedText = text.replace(urlRegex, (url) => {
      // Exclude cases like "1.pakistan" or "1.pakistan.com"
      if (!/^(?:\d+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/i.test(url)) {
        // Prepend "https://" if the URL doesn't have a protocol specified
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return `<a href="${url}" >${url} </a>`;
      } else {
        return url;
      }
    });

    // Replace newline characters with HTML line breaks
    return replacedText.replace(/\n/g, "<br>");
  };

  const handleReport = async (post_id: number, status: any) => {
    try {
      setLoading(true);
      const response = await appServices.acceptRejectPost(post_id, status);
      console.log(response, "responseeeeeee");
      setLoading(false);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        ...styles.detailContainer,
        marginTop: 80,
      }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <ListItemButton onClick={() => navigate(-1)}>
          <Typography color="text.primary">{T("Mebuku_Community")}</Typography>
        </ListItemButton>

        <Typography color="text.primary">{T("Details")}</Typography>
      </Breadcrumbs>
      <div style={{ zIndex: -1 }}>
        <Carousel
          swipeable
          showIndicators={false}
          showThumbs={false}
          // selectedItem={slide}
          // onChange={(index) => setSlide(index)}
        >
          {postState?.images.length === 0 ? (
            <img
              style={{ ...styles.detailCarouselImg, objectFit: "contain" }}
              src={require("../assets/images/default.png")}
              alt=""
            />
          ) : (
            postState?.images.map((item: any) => (
              <div key={item.id} style={styles.detailCarousel}>
                <img
                  style={{ ...styles.detailCarouselImg, objectFit: "contain" }}
                  src={
                    process.env.REACT_APP_AWS_PATH_BUCKET +
                    "/images" +
                    item.path
                  }
                  alt=""
                />
              </div>
            ))
          )}
        </Carousel>
      </div>

      <span
        className="truncate"
        style={{
          ...styles.detailStateTxt,
          wordWrap: "break-word",
        }}
      >
        {postState.title}
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          style={{
            ...styles.detailPostDate,
          }}
        >
          {T("Post_date")}: {postState.created_at.split("T")[0]}
        </span>
        <>
          {postState?.user?.id === user.id &&
            postState.status !== "Reported" && (
              <Button
                onClick={() =>
                  navigate("/post", { state: { post: postState } })
                }
                color={"secondary"}
                variant="text"
                startIcon={<EditIcon />}
              >
                {T("Edit_Post")}
              </Button>
            )}
          {user?.source === "admin" && (
            <Button
              onClick={() => setReportConfirmation(true)}
              color={"warning"}
              variant="text"
              // startIcon={<EditIcon />}
            >
              {T("Report")}
            </Button>
          )}
        </>
      </div>

      <ListItem
        className=""
        alignItems="flex-start"
        style={{
          ...styles.detailListItem,
          borderBottomStyle: "solid",
          borderTopStyle: "solid",
          paddingLeft: 0,
        }}
      >
        <ListItemAvatar>
          {postState.is_anonymous === 3 ? (
            <Avatar alt="Remy Sharp" src={profile} />
          ) : (
            <Avatar
              alt="Remy Sharp"
              src={
                postState?.user?.image !== null
                  ? "data:image/jpeg;base64," + postState?.user?.image
                  : profile
              }
            />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={T("Author")} //author
          secondary={
            postState.is_anonymous === 1 ? (
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", color: "#4792E0" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {postState?.user?.nickname}
                </Typography>
              </React.Fragment>
            ) : postState.is_anonymous === 2 ? (
              <React.Fragment>
                <Typography
                  sx={{ display: "inline", color: "#4792E0" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {postState?.user?.name
                    ? postState?.user?.name
                    : postState?.user?.nickname}
                </Typography>
              </React.Fragment>
            ) : (
              "匿名"
            )
          }
        />
      </ListItem>
      <span style={styles.detailSummaryTxt}>{T("Summary")}</span>
      <div>
        <p
          style={{
            userSelect: "text",
            fontSize: 16,
            fontFamily: "mplus-1c-regular",
            wordWrap: "break-word",
          }}
          dangerouslySetInnerHTML={{
            __html: renderTextWithLinks(postState?.details),
          }}
          onClick={(event) => {
            const target = event.target as HTMLElement;
            if (target.tagName === "A") {
              event.preventDefault();
              const url = (target as HTMLAnchorElement).href;
              console.log("Link clicked:", url);
              // You can add additional logic here, such as opening the URL in a new tab/window
              //window.open(url, "_system");
              try {
                const messageData = {
                  url: url,
                  condition: true,
                };
                window.parent.postMessage(
                  { type: "url", data: messageData },
                  "*"
                );
              } catch (error) {}
            }
          }}
        ></p>
      </div>
      <div>
        {likeLoading ? (
          <CircularProgress />
        ) : (
          <IconButton
            onClick={() => handleThumbUp()}
            // onClick={() => handleVoteSection()}

            // onClick={() => handleLike(state.id, "like")}
            aria-label="thumbs"
          >
            <FontAwesomeIcon
              size={"lg"}
              icon={postState?.liked_by_me ? thumbup : faThumbsUp}
              color={
                postState?.liked_by_me
                  ? farmColor.farm_green500
                  : farmColor.farm_gray200
              }
            />
          </IconButton>
        )}
        <span style={styles.detailStatLike}>{postState?.likes_count}</span>
      </div>
      <div style={styles.detailVoteSec}>
        <div
          style={{
            ...styles.detailVoteSubSec,
            textAlign: "center",
            flexDirection: "column",
            paddingLeft: 5,
            paddingRight: 5,
            display: "flex",
          }}
        >
          <IconButton
            sx={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: voteSection
                ? colors.farm_gray100
                : colors.farm_blue200,
            }}
            color={"primary"}
            aria-label="comment"
            onClick={() => setVoteSection(false)}
          >
            <FontAwesomeIcon
              size={"lg"}
              icon={faCommentDots}
              color={farmColor.farm_gray200}
            />
          </IconButton>
          <span
            style={{
              ...styles.detailCommentTxt,
              color: voteSection ? colors.farm_gray200 : colors.farm_blue200,
            }}
          >
            {T("Comments")}
          </span>
        </div>
        <div
          style={{
            ...styles.detailVoteSubSec,
            textAlign: "center",
            flexDirection: "column",
            paddingLeft: 5,
            paddingRight: 5,
            display: "flex",
          }}
        >
          <IconButton
            sx={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: !voteSection
                ? colors.farm_gray100
                : colors.farm_blue200,
            }}
            color={"primary"}
            aria-label="vote"
            onClick={() => setVoteSection(true)}
          >
            <img width={30} height={30} src={VoteIcon} alt="" />
          </IconButton>
          <span
            style={{
              ...styles.detailVoteTxt,
              color: !voteSection ? colors.farm_gray200 : colors.farm_blue200,
            }}
          >
            {T("Vote")}
          </span>
        </div>
      </div>
      <Dialog
        open={reportConfirmation}
        onClose={() => setReportConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {T("Report")} {/* //are you sure? */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            この投稿を本当に報告しますか?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => setReportConfirmation(false)}
          >
            {T("No")}
          </Button>
          <Button
            sx={{ fontFamily: "mplus-1c-bold" }}
            color={"secondary"}
            onClick={() => handleReport(postState?.id, "report")}
            autoFocus
          >
            {T("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Divider />

      {voteSection ? (
        <div>
          <div style={styles.detailVoteContainer}>
            <p style={styles.detailVoteNormalTxt}>{T("Votes")}</p>
            {postState?.vote_options?.length !== 0 && (
              <p style={styles.detailPostDate}>
                {T("Voting_end_date")}
                {postState.expiry_date}
              </p>
            )}
          </div>
          <List
            sx={{
              // selected and (selected + hover) states

              // hover states
              "& .MuiListItemButton-root:hover": {
                bgcolor: colors.farm_gray100,
                "&, & .MuiListItemIcon-root": {
                  color: "black",
                },
                "&$focusVisible": {
                  backgroundColor: colors.farm_green100,
                },
                "& .MuiListItemButton-root:touch": {
                  bgcolor: colors.farm_green100,
                  "&, & .MuiListItemIcon-root": {
                    color: "black",
                  },
                },
              },
            }}
          >
            {voteOptions?.map((item: any) => (
              <ListItemButton
                key={item.id}
                onClick={() => handleClickOpen(item)}
                selected
                sx={{
                  backgroundColor: colors.farm_green100,
                  borderRadius: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  margin: 1,
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor:
                    postState?.voted_info?.my_votes[0] === item.id
                      ? colors.farm_green500
                      : colors.farm_green100,
                  fontFamily: "mplus-1c-bold",
                }}
                component="p"
              >
                <ListItemText
                  primary={
                    <span
                      style={{
                        ...styles.communiTitleDescrip,
                        wordWrap: "break-word",
                      }}
                    >
                      {item.option_text}
                    </span>
                  }
                  secondary={
                    viewStatus ? (
                      <div style={styles.detailVoteStatusTxt}>
                        <BorderLinearProgress
                          style={{ width: "90%" }}
                          variant="determinate"
                          value={item.percentage}
                        />
                        &nbsp;{item.percentage}&nbsp;%
                      </div>
                    ) : null
                  }
                />
              </ListItemButton>
            ))}
          </List>
          <div style={styles.detailStatusContainer}>
            {postState?.vote_options?.length !== 0 ? (
              <Button
                onClick={() => setViewStatus(!viewStatus)}
                style={{ borderRadius: 20 }}
                color={"secondary"}
                size="small"
                variant="outlined"
              >
                {viewStatus ? "投票に戻る" : T("View_voting_status")}
              </Button>
            ) : (
              <p style={styles.detailPostDate}>投票オプションはありません</p>
            )}

            <Dialog
              open={comment}
              onClose={() => setComment(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {T("Voting_completed_Would_you_like_to_add_a_comment")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {T("Your_nickname_will_be_displayed_differently")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color={"secondary"}
                  onClick={() => handleCommentButtons(false)}
                >
                  {T("No")}
                </Button>
                <Button
                  sx={{ fontFamily: "mplus-1c-bold" }}
                  color={"secondary"}
                  onClick={() => handleCommentButtons(true)}
                  autoFocus
                >
                  {T("Yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      ) : (
        <div style={{ minHeight: 200, marginBottom: 100 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 1,
            }}
          >
            <p style={styles.detailVoteNormalTxt}>{T("Comments")}</p>
            {user.id_kind !== "2" && postState?.enable_comments && (
              <div
                style={{
                  ...styles.detailVoteSubSec,
                  textAlign: "center",
                  flexDirection: "column",
                  paddingLeft: 5,
                  paddingRight: 5,
                  width: 60,
                }}
              >
                <IconButton
                  sx={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: colors.farm_green150,
                  }}
                  color={"primary"}
                  aria-label="vote"
                  onClick={() => handleCommentButtons(true)}
                >
                  <MapsUgcIcon />
                </IconButton>
              </div>
            )}
          </Box>
          {postState.comments.map((comment: any) => (
            <>
              {comment.status === "Approved" ? (
                <Box key={comment.id} sx={styles.detailCommentBox}>
                  <div style={styles.detailVoteContainer}>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          height: 20,
                          width: 20,
                          borderRadius: 10,
                          backgroundColor: colors.farm_green500,
                        }}
                      ></span>
                      &nbsp;
                      <p
                        style={{
                          fontFamily: "mplus-1c-bold",
                          fontSize: 16,
                          margin: 0,
                        }}
                      >
                        {comment?.user_nickname}
                      </p>
                    </div>
                    {comment.user_id === user.id && (
                      <Button
                        onClick={() => editCurrentCmt(comment)}
                        style={styles.detailPostDate}
                        variant="text"
                      >
                        {T("Edit_Comment")}
                      </Button>
                    )}
                  </div>

                  <Divider />

                  <Typography style={{ wordWrap: "break-word" }} fontSize={16}>
                    {comment.content}
                  </Typography>
                  <Stack
                    direction={{
                      xs: "row-reverse",
                      sm: "row",
                    }}
                  >
                    <div>
                      {commentLike === comment.id ? (
                        <CircularProgress />
                      ) : (
                        <IconButton
                          onClick={() =>
                            likeComment(comment?.liked_by_me, comment.id)
                          }
                          aria-label="thumbs"
                        >
                          <FontAwesomeIcon
                            size={"lg"}
                            icon={comment?.liked_by_me ? thumbup : faThumbsUp}
                            color={
                              comment?.liked_by_me
                                ? farmColor.farm_green500
                                : farmColor.farm_gray200
                            }
                          />
                        </IconButton>
                      )}
                      <span style={styles.detailStatLike}>
                        {comment?.likes_count}
                      </span>
                    </div>
                  </Stack>
                  <Typography sx={{ fontSize: 10, color: colors.farm_gray200 }}>
                    {moment(comment?.created_at).format("YYYY/MM/DD HH:mm")}
                  </Typography>
                </Box>
              ) : (
                <>
                  {comment?.user_id === user.id && (
                    <Box key={comment.id} sx={styles.detailCommentBox}>
                      <div style={styles.detailVoteContainer}>
                        <div style={{ display: "flex" }}>
                          <span
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 10,
                              backgroundColor:
                                comment.status === "Pending"
                                  ? colors.farm_yellow10
                                  : colors.farm_pink10,
                            }}
                          ></span>
                          &nbsp;
                          <p
                            style={{
                              fontFamily: "mplus-1c-bold",
                              fontSize: 16,
                              margin: 0,
                            }}
                          >
                            {comment?.user_nickname}
                          </p>
                        </div>
                        {comment.user_id === user.id && (
                          <Button
                            onClick={() => editCurrentCmt(comment)}
                            style={styles.detailPostDate}
                            variant="text"
                          >
                            {T("Edit_Comment")}
                          </Button>
                        )}
                      </div>

                      <Divider />

                      <Typography fontSize={16}>{comment.content}</Typography>
                      <Stack
                        direction={{
                          xs: "row-reverse",
                          sm: "row",
                        }}
                      >
                        <div>
                          {commentLike === comment.id ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              onClick={() =>
                                likeComment(comment?.liked_by_me, comment.id)
                              }
                              aria-label="thumbs"
                            >
                              <FontAwesomeIcon
                                size={"lg"}
                                icon={
                                  comment?.liked_by_me ? thumbup : faThumbsUp
                                }
                                color={
                                  comment?.liked_by_me
                                    ? farmColor.farm_green500
                                    : farmColor.farm_gray200
                                }
                              />
                            </IconButton>
                          )}
                          <span style={styles.detailStatLike}>
                            {comment?.likes_count}
                          </span>
                        </div>
                      </Stack>
                      <Typography
                        sx={{ fontSize: 10, color: colors.farm_gray200 }}
                      >
                        {moment(comment?.created_at).calendar()}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 10, color: colors.farm_red600 }}
                      >
                        コメントは非公開です
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          ))}

          <Backdrop
            sx={{
              color: "#fff",
              touchAction: "none",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={commentArea}
            onClick={() => console.log("ok")}
          >
            <TrapFocus open disableEnforceFocus>
              <Fade appear={false} in={commentArea}>
                <Paper
                  role="dialog"
                  aria-modal="true"
                  aria-label="banner"
                  square
                  variant="outlined"
                  tabIndex={-1}
                  sx={{
                    position: "fixed",
                    bottom: focused ? 180 : 0,
                    left: 0,
                    right: 0,
                    m: 0,
                    p: 2,
                    borderWidth: 0,
                    borderTopWidth: 1,
                  }}
                >
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    gap={2}
                  >
                    <Box
                      sx={{
                        flexShrink: 1,
                      }}
                    >
                      <Stack
                        gap={4}
                        sx={{
                          flexShrink: 1,

                          alignItems: "center",
                        }}
                        direction={{
                          xs: "row",
                          sm: "row",
                        }}
                      >
                        <IconButton sx={{ p: "10px" }} aria-label="menu">
                          <FontAwesomeIcon
                            onClick={() => setCommentArea(false)}
                            size={"lg"}
                            icon={faClose}
                            color={farmColor.farm_gray200}
                          />
                        </IconButton>
                        <Typography fontWeight="bold">
                          {T("Add_comment")}
                        </Typography>
                      </Stack>

                      <Typography variant="body2">
                        相手のことを考え丁寧なコメントを心がけて下さい。不快や攻撃的な言葉づかいなどは掲載を削除させていただくことがあります。
                      </Typography>
                      {commentArea && (
                        <TextField
                          autoFocus
                          focused={true}
                          onFocus={onFocus}
                          // onBlur={onBlur}
                          ref={focusCommentArea}
                          fullWidth
                          id="filled-multiline-static"
                          hiddenLabel
                          inputProps={{ maxLength: 200 }}
                          multiline
                          minRows={4}
                          maxRows={6}
                          onChange={(ev) =>
                            setCommentText(() => ev.target.value)
                          }
                          value={commentText}
                          variant="filled"
                        />
                      )}
                    </Box>
                    <Stack
                      gap={4}
                      direction={{
                        xs: "row-reverse",
                        sm: "row",
                      }}
                      sx={{
                        flexShrink: 0,
                        alignSelf: { xs: "flex-end", sm: "center" },
                      }}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          disabled={commentText.trim() === ""}
                          size="small"
                          onClick={() => postComment()}
                          variant="contained"
                          color="secondary"
                        >
                          {T("Post")}
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Paper>
              </Fade>
            </TrapFocus>
          </Backdrop>
        </div>
      )}
      <LoadingModal
        visible={loading}
        handleClose={() => console.log("close")}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snakeText !== "" ? true : false}
        autoHideDuration={3000}
        sx={{ width: "94%" }}
        onClose={() => setSnakeText("")}
      >
        <Alert
          onClose={() => setSnakeText("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snakeText}
        </Alert>
      </Snackbar>
    </div>
  );
}
