import React, { FunctionComponent, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Modal from "@mui/material/Modal";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import DeleteIcon from "@mui/icons-material/Delete";

interface ThumbnailProps {
  item: any;
  deleteItem(item: any): void;
  showDelete?: boolean;
}

const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  item,
  deleteItem,
  showDelete = false,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [errorMap, setErrorMap] = useState<{ [key: number]: boolean }>({});
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleImageClick = (index: number) => {
    setModalOpen(true);
  };

  const handleError = (index: number) => {
    setErrorMap((prev) => ({ ...prev, [index]: true }));
  };

  const getGridColumnSpan = (index: number) => {
    const imagesLength = item.images.length;
    if (imagesLength === 1) return "span 4"; // Full width
    if (imagesLength === 2) return "span 2"; // Two per row
    if (imagesLength === 3) return index === 2 ? "span 4" : "span 2"; // Last image full width
    if (imagesLength === 4) return "span 2"; // Two per row
    if (imagesLength === 5) return index < 3 ? "span 2" : "span 1"; // 2 in the first row, 3 in the second
    return "span 1";
  };

  const images = item.images || [];
  const defaultImage = require("../../assets/images/default.png");

  return (
    <>
      {/* Grid Display */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "0px",
        }}
      >
        {images.map((image: any, index: number) => (
          <div
            key={index}
            onClick={() => handleImageClick(index)}
            style={{
              cursor: "pointer",
              paddingBottom: "100%",
              position: "relative",
              overflow: "hidden",
              borderWidth: 2,
              borderColor: "white",
              borderStyle: "solid",
              backgroundColor: "#f0f0f0",
              gridColumn: getGridColumnSpan(index),
            }}
          >
            <img
              alt=""
              onError={() => handleError(index)}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={
                errorMap[index]
                  ? defaultImage
                  : `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${image.path}`
              }
            />
            {showDelete && (
              <DeleteIcon
                style={{ top: 27, position: "relative" }}
                onClick={() => {
                  deleteItem(item);
                }}
                color={"warning"}
              />
            )}
          </div>
        ))}
        {images.length === 0 && (
          <div
            style={{
              gridColumn: "span 4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <img
              alt="default"
              src={defaultImage}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
        )}
      </div>

      {/* Modal with Slider */}
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="image-slider"
        aria-describedby="image-slider-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 9999,
          touchAction: "none", // Prevent default touch actions to ensure the TransformWrapper captures all gestures
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "background.paper",
            borderRadius: "1px",
            overflow: "hidden",
            position: "relative",
            touchAction: "manipulation", // Manipulate touch events within this box
          }}
        >
          {/* Close button */}
          <IconButton
            onClick={() => setModalOpen(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 10000,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Carousel
            responsive={{
              superLargeDesktop: {
                breakpoint: { max: 4000, min: 1024 },
                items: 1,
              },
              desktop: { breakpoint: { max: 1024, min: 768 }, items: 1 },
              tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
              mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
            }}
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={true}
            infinite={true}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {images.map((image: any, index: number) => (
              <div
                key={index}
                style={{
                  width: windowSize.width,
                  height: windowSize.height,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <TransformWrapper
                  minScale={1}
                  maxScale={5}
                  wheel={{ disabled: true }} // Disable wheel zoom
                  pinch={{ disabled: false }} // Enable pinch zoom
                  doubleClick={{ disabled: true }} // Disable double-click zoom
                  panning={{ disabled: false }} // Disable panning for more controlled zoom
                >
                  {({ zoomIn, zoomOut }) => (
                    <>
                      <TransformComponent>
                        <div
                          style={{
                            width: windowSize.width,
                            height: windowSize.height,
                            backgroundImage: `url(${
                              errorMap[index]
                                ? defaultImage
                                : `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${image.path}`
                            })`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "#f0f0f0", // Fallback color to ensure the div is visible
                            // touchAction: "none", // Prevent default touch actions on this element
                          }}
                        >
                          {/* Optional: add content inside the div to prevent collapse */}
                          <span style={{ visibility: "hidden" }}>Image</span>
                        </div>
                      </TransformComponent>
                      {/* Zoom In button */}
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          zoomIn();
                        }}
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          left: 16,
                          zIndex: 10000,
                          backgroundColor: "white",
                        }}
                      >
                        <ZoomInIcon />
                      </IconButton>

                      {/* Zoom Out button */}
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          zoomOut();
                        }}
                        sx={{
                          position: "absolute",
                          bottom: 16,
                          left: 64,
                          zIndex: 10000,
                          backgroundColor: "white",
                        }}
                      >
                        <ZoomOutIcon />
                      </IconButton>
                    </>
                  )}
                </TransformWrapper>
              </div>
            ))}
          </Carousel>
        </Box>
      </Modal>
    </>
  );
};

export default Thumbnail;
