import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import appServices from "../services/app-services/appServices";
import LoadingModal from "./LoadingModal";
import { styles } from "../pages/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { colors } from "../common/colors";
import { AsyncImage } from "loadable-image";
import { T } from "react-translator-component";
import downarrow from "../assets/images/downarrow.png";
import { useAppDispatch } from "../redux/store";
import { reset } from "../redux/Reducers/adminAuthReducer";
import setAuthToken from "../services/axios-util/set-auth-token";

export default function PendingPosts() {
  // const [dialogEdit, setDialogEdit] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [pagination, setPagination] = React.useState<any>({});
  const [snake, setSnake] = React.useState<any>("");
  const dispatch = useAppDispatch();
  // const [value, setValue] = React.useState("");
  // const [editGroup, setEditGroup] = React.useState<any>();
  const navigate = useNavigate();

  // const handleEditValue = async (name: string, id: number) => {
  //   setLoader(true);
  //   const editResult = await appServices.editGroup(name, id);
  //   setLoader(false);
  //   setDialogEdit(false);
  //   if (editResult.statusText === "OK") {
  //     g();
  //   } else {
  //     console.log('statusText == "False');
  //   }
  // };

  const handleMore = async () => {
    if (pagination?.next_page > 0) {
      setLoader(true);
      const posts = await appServices.getPendingPosts(pagination.next_page);
      setLoader(false);
      const newItems = [...data, ...posts.posts];
      setData(newItems);
      setPagination(posts.pagination);
    }
  };
  const timeouttxt = T("Session_timeout_Please_log_in_again");
  const g = async () => {
    try {
      setLoader(true);
      const posts = await appServices.getPendingPosts(1);
      setLoader(false);
      setData(posts.posts);
      setPagination(posts.pagination);
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.status === 403) {
        setSnake(timeouttxt);
        setTimeout(() => {
          dispatch(reset());
          setAuthToken("__");
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    g();
    // eslint-disable-next-line
  }, []);
  // const handleEditGroupValue = (value: any) => {
  //   setEditGroup({ ...editGroup, name: value });
  // };

  // const handleClose = () => {
  //   setDialogEdit(false);
  // };
  return (
    <TableContainer component={Paper}>
      <LoadingModal visible={loader} handleClose={() => console.log("value")} />
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>{T("Pending_Posts")}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right" style={{ width: 100 }}>
              <Typography style={{ ...styles.titletable, width: 80 }}>
                {T("Posts")} {data.length}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow
              key={row.title}
              onClick={() => navigate("/postview", { state: row })}
            >
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <AsyncImage
                    src={
                      row?.images[0]?.path
                        ? `${
                            process.env.REACT_APP_AWS_PATH_BUCKET +
                            "/images" +
                            row?.images[0]?.path
                          }?w=150&fit=crop&auto=format&dpr=2 2x`
                        : require("../assets/images/default.png")
                    }
                    style={{
                      width: 200,
                      height: 80,
                      backgroundColor: "lightgray",
                      borderRadius: 10,
                    }}
                  />
                  <Box marginLeft={2} sx={{ width: "100%" }}>
                    <Typography
                      className="truncate"
                      variant="subtitle1"
                      style={styles.titletable}
                    >
                      {row.title}
                    </Typography>
                    <Typography
                      className="truncate"
                      // variant="body2"
                      variant="subtitle1"
                      style={styles.desTable}
                    >
                      {row.details}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        ...styles.desTable,
                        fontWeight: "500",
                        color: colors.farm_gray200,
                      }}
                    >
                      {row.category.name}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="right">{row?.status}</TableCell>
              <TableCell align="right">
                <VisibilityIcon color="primary" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination.next_page > 0 ? (
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button onClick={() => handleMore()}>
            <img
              src={downarrow}
              alt=""
              style={{ width: 20, height: 20, margin: 15 }}
            />
          </Button>
        </div>
      ) : null}
      <Snackbar
        open={snake !== ""}
        autoHideDuration={3000}
        sx={{ width: "94%" }}
        onClose={() => setSnake("")}
      >
        <Alert
          onClose={() => setSnake("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snake}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}
