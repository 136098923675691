import { Stack, Button } from "@mui/material";
import { colors } from "../common/colors";

interface Props {
  title: string;
  onClick(): void;
}

function ButtonOutlinedCentered({ title, onClick }: Props) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", justifyContent: "center", m: 4 }}
    >
      <Button
        variant="outlined"
        onClick={onClick}
        sx={{
          borderRadius: "36px",
          height: "36px",
          borderColor: colors.farm_blue200, // Set outline color to blue
          color: colors.farm_gray200, // Set text color to black
          "&:hover": {
            borderColor: colors.farm_blue200, // Ensure outline color stays blue on hover
          },
        }}
      >
        {title}
      </Button>
    </Stack>
  );
}

export default ButtonOutlinedCentered;
