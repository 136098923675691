import { AWSError } from "aws-sdk";
import { PutObjectOutput } from "aws-sdk/clients/s3";
import { PromiseResult } from "aws-sdk/lib/request";
import s3UploadFiles from "../services/app-services/s3UploadFiles";


const removeImagesPrefix = (path:string) => {
  if (path.startsWith('/images')) {
    return path.substring(7); // Remove the first 7 characters which is "/images"
  }
  return path;
};
 const uploadMultipleImages = async (images:any) => {
    let promises: Promise<PromiseResult<PutObjectOutput, AWSError>>[] = [];
    images.map((image: { file: any; }, i: any) =>
      promises.push(s3UploadFiles.uploadFileAWS(image.file))
    );
    const img = await Promise.all(promises).then((uploadedImgs) => {
      const all = uploadedImgs.map((res) => {
        const ak = res.$response as any;
        const imgPath = { path: removeImagesPrefix(ak?.request?.httpRequest?.path )};
        return imgPath;
      });
      return all;
    });
    return img;
  };

  const uploadFiles={
    uploadMultipleImages
}
export default uploadFiles;