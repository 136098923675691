import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import {
  Alert,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
// import { useNavigate } from "react-router-dom";
import appServices from "../services/app-services/appServices";
import LoadingModal from "./LoadingModal";
import { styles } from "../pages/styles";
import { T } from "react-translator-component";
import { colors } from "../common/colors";
import downarrow from "../assets/images/downarrow.png";
import { reset } from "../redux/Reducers/adminAuthReducer";
import setAuthToken from "../services/axios-util/set-auth-token";
import { useAppDispatch } from "../redux/store";

export default function GroupTable() {
  const [dialog, setDialog] = React.useState(false);
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const [dialogEdit, setDialogEdit] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [value, setValue] = React.useState("");
  const [editGroup, setEditGroup] = React.useState<any>();
  const [deleteGroupId, setDeleteGroupId] = React.useState<number | null>(null);
  const [pagination, setPagination] = React.useState<any>({});
  const [snake, setSnake] = React.useState<any>("");
  const dispatch = useAppDispatch();
  const handleClickOpen = () => {
    setDialog(true);
  };
  const timeoutlogintxt :any = T("Session_timeout_Please_log_in_again")
  const handleValue = async () => {
    try {
      setLoader(true);
      const results = await appServices.setGroups(value);
      setLoader(false);
      if (results.statusText === "Created") {
        g();
      } else {
        console.log('statusText == "False');
      }
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.status === 403) {
        setSnake(timeoutlogintxt);
        setTimeout(() => {
          dispatch(reset());
          setAuthToken("__");
        }, 1000);
      }
    }
  };
  const handleEditValue = async (name: string, id: number) => {
    setLoader(true);
    const editResult = await appServices.editGroup(name, id);
    setLoader(false);
    setDialogEdit(false);
    setEditGroup("")
    if (editResult.statusText === "OK") {
      g();
    } else {
      console.log('statusText == "False');
    }
  };

  const handleDelete = async (id: number) => {
    setDialogDelete(true);
    setDeleteGroupId(id);
  };
  const handleDeleteDialog = async (id: number) => {
    const result = await appServices.deleteGroup(id);
    if (result.statusText === "OK") {
      g();
    }
    setDialogDelete(false);
  };

  const handleEditOpen = (item: any) => {
    setEditGroup(item);

    setTimeout(() => {
      setDialogEdit(true);
    }, 100);
  };
  const handleMore = async () => {
    try {
      if (pagination?.next_page > 0) {
        setLoader(true);
        const groups = await appServices.getGroupsApp(pagination.next_page);
        setLoader(false);
        const newItems = [...data, ...groups.groups];
        setData(newItems);
        console.log(groups.pagination);
        setPagination(groups.pagination);
      }
    } catch (error) {
      console.log("error");
    } finally {
      setLoader(false);
    }
  };
  const timeouttxt = T("Session_timeout_Please_log_in_again")
  const g = async () => {
    try {
      setLoader(true);
      const groups = await appServices.getGroupsApp(1);
      setLoader(false);
      setData(groups.groups);
      setPagination(groups.pagination);
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.status === 403) {
        setSnake(timeouttxt);
        setTimeout(() => {
          dispatch(reset());
          setAuthToken("__");
        }, 1000);
      }
    }

    // console.log(groups, 'groupssssssssssssssssssss')
    // console.log(groups.posts_count,'posts_countt')
    // if (groups.posts_count > 0) {
    //   setEdit(true);
    // }
  };
  React.useEffect(() => {
    g();
    // eslint-disable-next-line
  }, []);
  const handleEditGroupValue = (value: any) => {
    setEditGroup({ ...editGroup, name: value });
  };

  const handleClose = () => {
    setDialog(false);
    setDialogEdit(false);
    setEditGroup("")
    setValue("");
  };
  return (
    <TableContainer component={Paper}>
      <LoadingModal
        visible={loader}
        handleClose={() => console.log(value, "value")}
      />
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>{T("Groups")}</TableCell>
            <TableCell align="right">
              <Dialog open={dialogEdit} onClose={() => handleClose()}>
                <DialogActions style={styles.groupDialog}>
                  <IconButton color="inherit">
                    <Badge color="secondary">
                      <CloseIcon
                        style={styles.groupBadge}
                        onClick={() => handleClose()}
                      />
                    </Badge>
                  </IconButton>
                </DialogActions>
                <DialogContent>
                  <div
                    style={{
                      ...styles.groupDialogContainer,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        ...styles.groupDialogContainer,
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                      }}
                    >
                      <span style={styles.groupTitleName}>
                        {T("Edit_Group_Name")}
                      </span>
                      {editGroup?.name && (
                        <TextField
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={editGroup?.name}
                          onChange={(e: any) =>
                            handleEditGroupValue(e.target.value)
                          }
                          style={styles.groupTextField}
                        />
                      )}
                    </div>
                    <Button
                      onClick={() =>
                        handleEditValue(editGroup.name, editGroup.id)
                      }
                      disabled={editGroup?.name === ""}
                      style={styles.groupDialogBtn}
                      size={"small"}
                      variant="contained"
                    >
                      <span style={styles.groupDialogBtnTxt}>{T("Save")}</span>
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </TableCell>

            <TableCell align="right" style={{ width: 200 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton color="inherit">
                  <Badge color="secondary">
                    <CreateIcon onClick={() => handleClickOpen()}></CreateIcon>
                  </Badge>

                  <Dialog open={dialog} onClose={() => handleClose()}>
                    <DialogActions style={styles.groupDialogClose}>
                      <IconButton color="inherit">
                        <Badge color="secondary">
                          <CloseIcon
                            style={styles.groupDialogCloseIcon}
                            onClick={handleClose}
                          />
                        </Badge>
                      </IconButton>
                    </DialogActions>
                    <DialogContent>
                      <div
                        style={{
                          ...styles.groupDialogContainer,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            ...styles.groupDialogContainer,
                            display: "flex",
                            flexDirection: "column",
                            alignSelf: "center",
                          }}
                        >
                          <span style={styles.groupTitleName}>
                            {T("Group_Name")}
                          </span>

                          <TextField
                            size="small"
                            id="outlined-basic"
                            variant="outlined"
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            style={styles.groupTextField}
                          />
                        </div>
                        <Button
                          onClick={() => handleValue()}
                          disabled={value === ""}
                          style={styles.groupDialogBtn}
                          size={"small"}
                          variant="contained"
                        >
                          <span
                            onClick={() => handleClose()}
                            style={styles.groupDialogBtnTxt}
                          >
                            {T("Create_Group")}
                          </span>
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                </IconButton>
                <Typography onClick={() => handleClickOpen()}>
                  {T("create_group")}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row.userName}>
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <Avatar alt={row.userName} src={row.avatarUrl} />
                  <Box marginLeft={2}>
                    <Typography variant="subtitle1" style={styles.titletable}>
                      {row.name}
                    </Typography>
                    <Typography variant="body2" style={styles.desTable}>
                      {row.description}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => handleEditOpen(row)}>
                  <EditIcon color="primary" />
                </IconButton>
              </TableCell>
              <TableCell align="right">
                {row.posts_count === 0 && (
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <Dialog open={dialogDelete} onClose={() => setDialogDelete(false)}>
          <DialogTitle
            style={{
              ...styles.adminUserDialogTitle,
              color: colors.farm_red600,
              textAlign: "center",
            }}
          >
            Are you sure you want to delete this group?
          </DialogTitle>
          <DialogContent>
            <div style={styles.adminUserDialogContent}>
              <Button
                onClick={() => {
                  if (deleteGroupId !== null) {
                    handleDeleteDialog(deleteGroupId); // Trigger delete action with the stored ID if it's not null
                  }
                }}
                variant="contained"
                color={"error"}
                sx={{ marginRight: 5, textAlign: "center" }}
              >
                Delete
              </Button>

              <Button
                onClick={() => setDialogDelete(false)}
                variant="contained"
                style={{
                  textAlign: "center",
                  backgroundColor: "#fea501",
                }}
              >
                {T("Cancel")}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </Table>
      {pagination?.next_page > 0 ? (
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button onClick={() => handleMore()}>
            <img
              src={downarrow}
              alt=""
              style={{ width: 20, height: 20, margin: 15 }}
            />
          </Button>
        </div>
      ) : null}
      <Snackbar
        open={snake !== ""}
        autoHideDuration={3000}
        sx={{ width: "94%" }}
        onClose={() => setSnake("")}
      >
        <Alert
          onClose={() => setSnake("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snake}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}
