import React, { useState } from "react";
import { colors } from "../../common/colors";

interface Props {
  description: string;
}

const PostDescSeeMore = ({ description }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const renderTextWithLinks = (text: string) => {
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})[\S]*\b/g;

    const replacedText = text.replace(urlRegex, (url) => {
      if (!/^(?:\d+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/i.test(url)) {
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else {
        return url;
      }
    });

    return replacedText.replace(/\n/g, "<br>");
  };

  const getTruncatedDescription = (text: string, limit: number) => {
    let truncated = text.slice(0, limit);
    let lastOpen = truncated.lastIndexOf("<a ");
    let lastClose = truncated.lastIndexOf("</a>");

    if (lastOpen > lastClose) {
      truncated = truncated.slice(0, lastOpen) + "...";
    } else if (truncated.length < text.length) {
      truncated += "...";
    }

    return renderTextWithLinks(truncated);
  };

  const renderDescription = () => {
    const truncatedDescription = getTruncatedDescription(description, 80);
    const descriptionHtml = isExpanded
      ? renderTextWithLinks(description)
      : truncatedDescription;

    return (
      <div
        style={{
          fontSize: 14,
          marginLeft: 6,
          marginRight: 6,
          lineBreak: "anywhere",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: descriptionHtml,
          }}
          onClick={(event) => {
            const target = event.target as HTMLElement;
            if (target.tagName === "A") {
              event.preventDefault();
              const url = (target as HTMLAnchorElement).href;
              console.log("Link clicked:", url);
              // You can add additional logic here, such as opening the URL in a new tab/window
              //window.open(url, "_system");
              try {
                const messageData = {
                  url: url,
                  condition: true,
                };
                window.parent.postMessage(
                  { type: "url", data: messageData },
                  "*"
                );
              } catch (error) {}
            }
          }}
        />
        {description.length > 80 && (
          <span
            style={{
              fontSize: 12,
              color: colors.farm_gray200,
              cursor: "pointer",
              display: "block", // Make sure the text stays on a new line if desired
              marginTop: 8, // Some space between the text and the "See more/less"
            }}
            onClick={toggleDescription}
          >
            {isExpanded ? "See less" : "See more"}
          </span>
        )}
      </div>
    );
  };

  return <div className="post">{renderDescription()}</div>;
};

export default PostDescSeeMore;
