export const colors={

    farm_green500:'rgba(134,187,87,1)',
    farm_green100:'rgba(134,187,87,0.1)',
    farm_green150:'rgba(134,187,87,0.5)',
    farm_green200:'#86BB57',

    farm_gray100:'#d9e2e6',
    farm_gray10:'#f8f9fa',
    farm_gray15: '#ECEFF1',
    farm_gray200:'#A2A2A2', 

    farm_blue200:'#4792E0',

    farm_pink10:'#F8A7BA',

    farm_red10:'rgba(252, 199, 198, .8)',
    farm_red600:'#d32f2f',

    farm_yellow10:'rgba(255, 252, 172, .8)',
    farm_yellow100:'#FFD41D',
    farm_yellow800:'#C9B251',
    
    farm_white:'#ffffff',
    

}