import { Box, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { colors } from "../../common/colors";
import SelectedUser from "../../components/items/SelectedUser";
import { useAppDispatch } from "../../redux/store";
import profile from "../../assets/images/profile_img.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../App.css";
import {
  addSelectedUser,
  getAllUsers,
  removeSelectedUser,
} from "../../redux/Reducers/postReducer";
import { useSelector } from "react-redux";
import LoadingModal from "../LoadingModal";
import appServices from "../../services/app-services/appServices";
// Dummy data for users

let width = window.innerWidth * 0.9;
let height = window.innerHeight - 200;
// Styled components
const Container = styled.div`
  width: ${width}
  margin: auto;
  padding: 20px;
  padding-top:0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  overflow:auto
`;

const SearchField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const UserList = styled.ul`
  list-style: none;
  padding: 0;
  overflow: auto; /* Corrected overflow property */
  max-height: ${height}; /* Set a max height to enable scrolling */
`;

const UserItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const AddButton = styled.button`
  margin-left: auto;
  border-color: ${colors.farm_green500};
  border-radius: 20px;
  border-width: 2px;
  width: 40px;
  height: 40px;
  color: ${colors.farm_green500};
`;
const ParentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ClearButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: white;
`;

// Main component

interface Props {
  isEdit: boolean;
  closeModal(users: any[]): void;
}
const AddUserModel = ({ closeModal, isEdit }: Props) => {
  const dispatch = useAppDispatch();
  const scrollRef = useRef<any>(null);
  const { user } = useSelector((state: any) => state.userAuth);
  const { allUsers, selectedUsers, isLoadingUsers } = useSelector(
    (state: any) => state.posts
  );
  const [users, setUsers] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>("");
  // const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleAddUser = (user: any) => {
    dispatch(addSelectedUser(user));
    // setSelectedUsers([...selectedUsers, user]);
    // setUsers(users.filter((u: any) => u?.id !== user?.id));
  };

  const handleRemoveUser = async (user: any) => {
    if (user?.id !== user?.user_id) {
      // console.log(user);
      const res = await appServices.deletePostPrivateUser(user.id);
      console.log(res);
    }
    dispatch(removeSelectedUser(user?.id));
    // setSelectedUsers(selectedUsers.filter((u: any) => u.id !== user.id));
    // setUsers([...users, user]);
  };

  useEffect(() => {
    // Filter users based on search term
    if (searchTerm) {
      setUsers(
        allUsers?.filter((user: any) =>
          user.nickname?.toLowerCase().includes(searchTerm?.toLowerCase())
        )
      );
    } else {
      setUsers([]);
    }
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    dispatch(getAllUsers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0;
    }
  }, [selectedUsers]);

  return (
    <Container>
      <LoadingModal
        visible={isLoadingUsers}
        handleClose={() => console.log("")}
      />
      <ParentDiv>
        <ClearButton
          onClick={() => {
            console.log("close");
            closeModal(selectedUsers);
          }}
        >
          <Typography
            sx={{ fontSize: 12, color: colors.farm_gray200 }}
            variant="body2"
            color="textPrimary"
          >
            閉じる
          </Typography>
          <KeyboardArrowDownIcon />
        </ClearButton>
      </ParentDiv>
      <div>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textPrimary">
            この投稿を見られるユーザー
          </Typography>
          <Typography
            variant="body2"
            style={{
              backgroundColor: colors.farm_blue200,
              borderRadius: "4px",
              color: "white",
              padding: "2px 8px",
              marginLeft: "8px",
              marginBottom: "2px",
            }}
          >
            {selectedUsers?.length}
          </Typography>
        </Box>
        <Carousel
          responsive={{
            superLargeDesktop: {
              breakpoint: { max: 4000, min: 1024 },
              items: 5, // Number of items to show on large screens
            },
            desktop: { breakpoint: { max: 1024, min: 768 }, items: 3 },
            tablet: { breakpoint: { max: 768, min: 464 }, items: 2 },

            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 2, // Ensure at least 2 items on mobile screens
            },
          }}
          swipeable={true} // Allows swipe gestures on mobile devices
          draggable={true} // Enables dragging on desktop
          showDots={false} // Optionally show/hide dots
          arrows={true} // Optionally show/hide arrows
          infinite={false} // Optionally make the carousel infinite
          keyBoardControl={true} // Allows keyboard control
          itemClass="carousel-item-padding" // Item class
        >
          {[...selectedUsers]
            ?.slice()
            .reverse()
            .map((user: any) => (
              <SelectedUser
                isEdit={isEdit}
                key={user.id}
                user={user}
                onDelete={() => handleRemoveUser(user)}
              />
            ))}

          {/* </Carousel> */}
        </Carousel>
      </div>
      <SearchField
        type="text"
        placeholder="Search users..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <UserList>
        {users
          ?.slice()
          .sort((a: any, b: any) => a.id - b.id)
          .map((userAll: any) => {
            const isUserSelected = selectedUsers.some(
              (selectedUser: any) => selectedUser.user_id === userAll.id
            );
            const currentUserIsLoggedInUser = user && user.id === userAll.id;
            return (
              <UserItem key={userAll.id}>
                <Avatar
                  src={
                    userAll.image !== null
                      ? "data:image/jpeg;base64," + userAll.image
                      : profile
                  }
                  alt={userAll.nickname}
                />
                {userAll.nickname}
                {!isUserSelected && !currentUserIsLoggedInUser && (
                  <AddButton onClick={() => handleAddUser(userAll)}>
                    +
                  </AddButton>
                )}
              </UserItem>
            );
          })}
      </UserList>
    </Container>
  );
};

export default AddUserModel;
