import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { reset } from "../redux/Reducers/adminAuthReducer";
import setAuthToken from "../services/axios-util/set-auth-token";
import {
  Alert,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CloseIcon from "@mui/icons-material/Close";
// import { useNavigate } from "react-router-dom";
import clogo from "../assets/sliderimages/community_logo.png";
import { styles } from "../pages/styles";
import { T } from "react-translator-component";
import appServices from "../services/app-services/appServices";
import LoadingModal from "./LoadingModal";
import downarrow from "../assets/images/downarrow.png";
import { useSelector } from "react-redux";
import { colors } from "../common/colors";
import { useAppDispatch } from "../redux/store";

// function createData(avatarUrl: string, userName: string, description: string) {
//   return { avatarUrl, userName, description };
// }

// const rows = [
//   createData("/path/to/avatar1.png", "John Doe", "App Developer"),
//   createData("/path/to/avatar2.png", "Jane Smith", "Software Engineer"),
//   createData("/path/to/avatar3.png", "Alice Johnson", "UI/UX Designer"),
// ];

export default function AccessibleTable() {
  const [dialog, setDialog] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [dialogDelete, setDialogDelete] = React.useState(false);
  const [deleteID, setID] = React.useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState<any>("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [pagination, setPagination] = React.useState<any>({});
  const [value, setValue] = React.useState<any>(0);
  const { admin } = useSelector((state: any) => state.adminAuth);
  const [snake, setSnake] = React.useState<any>("");
  const dispatch = useAppDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const [editUser, setEditUser] = React.useState<any>();
  const handleAdmin = () => {
    console.log(value, "value radio btn");
  };
  const handleFacilitator = () => {
    console.log(value, "value radio btn");
  };
  const handleClickOpen = () => {
    if (editUser === undefined) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setValue("");
    }

    setDialog(true);
    // console.log("setDialog(true)");
  };
  React.useEffect(() => {
    g();
    // eslint-disable-next-line
  }, []);
  React.useEffect(() => {}, [data]);

  const handleClose = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setValue("");
    setDialog(false);
    setEditUser(undefined);
    // console.log("dialogClose")
  };

  const update = async () => {
    if (
      firstName.trim() &&
      lastName.trim() &&
      email.trim() &&
      password.trim() &&
      confirmPassword.trim()
    ) {
      setLoader(true);
      const results = await appServices.updateUser(
        editUser.id,
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        value
      );
      await g();
      setLoader(false);
      if (results.statusText === "OK") {
      } else {
        console.log('statusText == "False');
      }
      setDialog(false);
      console.log();
    } else {
      alert("Please fill all required fields.");
    }
  };

  const handleValue = async () => {
    if (
      firstName.trim() &&
      lastName.trim() &&
      email.trim() &&
      password.trim() &&
      confirmPassword.trim()
    ) {
      setLoader(true);
      const results = await appServices.createUser(
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        value
      );
      await g();
      setLoader(false);
      if (results.statusText === "OK") {
      } else {
        console.log('statusText == "False');
      }
      setDialog(false);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setValue("");
      console.log();
    } else {
      alert("Please fill all required fields.");
    }
  };
  const handleMore = async () => {
    if (pagination?.next_page > 0) {
      setLoader(true);
      const users = await appServices.getUsers(pagination.next_page);
      setLoader(false);
      const newItems = [...data, ...users.users];
      setData(newItems);
      setPagination(users.pagination);
    }
  };
  const timeoutlogintxt: any = T("Session_timeout_Please_log_in_again");

  const g = async () => {
    try {
      setLoader(true);
      const users = await appServices.getUsers(1);
      setData(users.users);
      setPagination(users.pagination);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      if (error?.response?.status === 403) {
        setSnake(timeoutlogintxt);
        setTimeout(() => {
          dispatch(reset());
          setAuthToken("__");
        }, 1000);
      }
      console.error("Error fetching users:", error);
    } finally {
      setLoader(false);
      setDialog(false);
    }
  };

  const handleDelete = async (id: number) => {
    setDialogDelete(true);
    setID(id);
  };
  const handleDeleteDialog = async (id: number) => {
    try {
      const result = await appServices.deleteUser(id);
      if (result.statusText === "OK") {
      }
      g();
      setDialogDelete(false);
    } catch (error) {
      setDialogDelete(false);
      setTimeout(() => {
        alert("error");
      }, 300);
    }
  };

  const handleEditOpen = async (user: any) => {
    setEditUser(user);
    handleClickOpen();
    setFirstName(user?.name);
    setLastName(user.nickname);
    setEmail(user.email);
    setValue(user.is_facilitator);
  };

  return (
    <TableContainer component={Paper}>
      <LoadingModal visible={loader} handleClose={() => console.log("value")} />
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>{T("Users")}</TableCell>
            <TableCell align="right">
              <Dialog
                open={dialogDelete}
                onClose={() => setDialogDelete(false)}
              >
                <DialogTitle
                  style={{
                    ...styles.adminUserDialogTitle,
                    color: colors.farm_red600,
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to delete this group?
                </DialogTitle>
                <DialogContent>
                  <div style={styles.adminUserDialogContent}>
                    <Button
                      onClick={() => {
                        if (deleteID) {
                          handleDeleteDialog(deleteID); // Trigger delete action with the stored ID if it's not null
                        }
                      }}
                      variant="contained"
                      color={"error"}
                      sx={{ marginRight: 5, textAlign: "center" }}
                    >
                      Delete
                    </Button>

                    <Button
                      onClick={() => setDialogDelete(false)}
                      variant="contained"
                      style={{
                        textAlign: "center",
                        backgroundColor: "#fea501",
                      }}
                    >
                      {T("Cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </TableCell>
            <TableCell align="right">
              <IconButton color="inherit">
                <Badge color="secondary">
                  <GroupAddIcon onClick={handleClickOpen} />
                </Badge>
                <Dialog open={dialog} onClose={handleClose}>
                  <div>
                    <DialogActions style={styles.tableDialog}>
                      <IconButton color="inherit">
                        <Badge color="secondary">
                          <CloseIcon
                            style={styles.tableCloseIcon}
                            onClick={handleClose}
                          />
                        </Badge>
                      </IconButton>
                    </DialogActions>
                    <DialogContent>
                      <div style={styles.tableDialogContent}>
                        <div
                          style={{
                            ...styles.tableDialogSubContent,
                            flexDirection: "column",
                          }}
                        >
                          <img
                            src={clogo}
                            alt="BigCo Inc. logo"
                            style={{
                              width: 70,
                              height: 70,
                              alignSelf: "center",
                              marginBottom: 20,
                            }}
                          />
                          <span style={styles.tableTitleUser}>
                            {T("Create_New_User")}
                          </span>
                          <div
                            style={{
                              ...styles.tableFieldSec,
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                ...styles.tableInputField,
                                flexDirection: "column",
                              }}
                            >
                              <span style={styles.tableInputFieldTitle}>
                                {T(`First_Name`)}
                              </span>
                              <TextField
                                placeholder={T(`First_Name`)}
                                size="small"
                                value={firstName}
                                onChange={(e: any) =>
                                  setFirstName(e.target.value)
                                }
                                style={styles.tableInputFieldTxt}
                              />
                            </div>
                            <div
                              style={{
                                ...styles.tableInputField,
                                flexDirection: "column",
                              }}
                            >
                              <span style={styles.tableInputFieldTitle}>
                                {"ニックネーム"}
                              </span>
                              <TextField
                                placeholder={"ニックネーム"}
                                size="small"
                                value={lastName}
                                onChange={(e: any) =>
                                  setLastName(e.target.value)
                                }
                                style={styles.tableInputFieldTxt}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              ...styles.tableInputSec,
                              flexDirection: "row",
                            }}
                          >
                            <div
                              style={{
                                ...styles.tableInputFields,
                                flexDirection: "column",
                              }}
                            >
                              <span style={styles.tableInputFieldTitle}>
                                {T(`Email`)}
                              </span>
                              <TextField
                                value={email}
                                placeholder={T(`Email`)}
                                size="small"
                                style={styles.tableInputFieldTxt}
                                onChange={(ev) => setEmail(ev.target.value)}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              ...styles.tableFieldSec,
                              flexDirection: "row",
                              marginTop: 10,
                            }}
                          >
                            <div
                              style={{
                                ...styles.tableInputField,
                                flexDirection: "column",
                              }}
                            >
                              <span style={styles.tableInputFieldTitle}>
                                {T("Password")}
                              </span>
                              <TextField
                                placeholder={T(`Password`)}
                                size="small"
                                type="password"
                                value={password}
                                onChange={(e: any) =>
                                  setPassword(e.target.value)
                                }
                                style={styles.tableInputFieldTxt}
                              />
                            </div>
                            <div
                              style={{
                                ...styles.tableInputField,
                                flexDirection: "column",
                              }}
                            >
                              <span style={styles.tableInputFieldTitle}>
                                {T(`Confirm_Password`)}
                              </span>
                              <TextField
                                placeholder={T(`Confirm_Password`)}
                                size="small"
                                type="password"
                                value={confirmPassword}
                                onChange={(e: any) =>
                                  setConfirmPassword(e.target.value)
                                }
                                style={styles.tableInputFieldTxt}
                              />
                            </div>
                          </div>

                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                            style={{ flexDirection: "row", marginTop: 30 }}
                          >
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Admin"
                              onClick={() => handleAdmin()}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Mebuku Navigator"
                              onClick={() => handleFacilitator()}
                            />
                          </RadioGroup>

                          {editUser === undefined ? (
                            <Button
                              onClick={() => handleValue()}
                              // onClick={handleValue}
                              style={styles.tableCreateBtn}
                              // disabled={value === ""}
                              size={"small"}
                              variant="contained"
                            >
                              <span style={styles.tableCreateBtnTxt}>
                                {T(`Create`)}
                              </span>
                            </Button>
                          ) : (
                            <Button
                              onClick={() => update()}
                              // onClick={handleValue}
                              style={styles.tableCreateBtn}
                              // disabled={value === ""}
                              size={"small"}
                              variant="contained"
                            >
                              <span style={styles.tableCreateBtnTxt}>
                                {T(`Update`)}
                              </span>
                            </Button>
                          )}
                        </div>
                      </div>
                    </DialogContent>
                  </div>
                </Dialog>
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <Avatar alt={row?.name} src={row?.image} />
                  <Box marginLeft={2}>
                    <Typography
                      variant="subtitle1"
                      style={styles.tableUserName}
                    >
                      {row?.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "300", userSelect: "all" }}
                    >
                      {row?.email}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="right">
                {(admin?.email === "sa@j-com.co.jp" ||
                  admin?.email === "whamlin@jcius.com") &&
                  (row?.email !== "sa@j-com.co.jp" ||
                    row?.email !== "whamlin@jcius.com") && (
                    <IconButton onClick={() => handleEditOpen(row)}>
                      <EditIcon color="primary" />
                    </IconButton>
                  )}
              </TableCell>
              <TableCell align="right">
                {(admin?.email === "sa@j-com.co.jp" ||
                  admin?.email === "whamlin@jcius.com") &&
                  (row?.email !== "sa@j-com.co.jp" ||
                    row?.email !== "whamlin@jcius.com") && (
                    <IconButton onClick={() => handleDelete(row.id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination.next_page > 0 ? (
        <div
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button onClick={() => handleMore()}>
            <img
              src={downarrow}
              alt=""
              style={{ width: 20, height: 20, margin: 15 }}
            />
          </Button>
        </div>
      ) : null}
      <Snackbar
        open={snake !== ""}
        autoHideDuration={3000}
        sx={{ width: "94%" }}
        onClose={() => setSnake("")}
      >
        <Alert
          onClose={() => setSnake("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snake}
        </Alert>
      </Snackbar>
    </TableContainer>
  );
}
