import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styles } from "./pages/styles";
import userAuth from "./util/auth-util";
import { T } from "react-translator-component";

export default function Unauthorized() {
  const { user } = useSelector((state: any) => state.userAuth);
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.auth_token) {
      userAuth.loginUser(user);
      navigate("/home");
    } else {
      console.log(user);
    }
  }, [user, navigate]);
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        style={styles.introSliderImg}
        src={require("./assets/sliderimages/community_logo.png")}
        alt=""
      />
      <p>{T("Session_timeout_Please_log_in_again")}</p>
    </div>
  );
}
