// import { Breadcrumbs, ListItemButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// import VoteIcon from "../assets/images/svg/vote_black.svg";
// import { T } from "react-translator-component";
import { useAppDispatch } from "../redux/store";
import { useSelector } from "react-redux";
import LoadingModal from "../components/LoadingModal";
import { getTopPosts, removeTopPost } from "../redux/Reducers/postReducer";

import SearchBar from "../components/Phase2/Searchbar";
import VerticalListP2 from "../components/lists/VerticalListP2";
import appServices from "../services/app-services/appServices";
import RankingHeader from "../components/Phase2/RankingHeader";
import authService from "../services/app-services/auth-service";
import { loginWithToken } from "../redux/Reducers/userAuthReducer";
import PersonalInfoPopup from "../components/Models/PersonalInfoPopup";

export default function VotingSeeMorePage() {
  const dispatch = useAppDispatch();
  // const posts = useLocation().state;
  const { isLoadingTopPosts, topPosts } = useSelector(
    (state: any) => state.posts
  );
  const { user } = useSelector((state: any) => state.userAuth);
  const [infoPopUp, setInfoPopUp] = useState<boolean>(false);

  const navigate = useNavigate();
  useEffect(() => {
    apiCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(user);
    if (!user?.name && user?.id_kind === "1") {
      console.log("in it");
      setInfoPopUp(true);
    } else {
      console.log("in it");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // const onNext = () => {
  //   if (!user?.name) {
  //     console.log("in it");
  //     // getPersonalBI();
  //   } else {
  //     console.log("in it");
  //   }
  // };

  const apiCalls = async () => {
    try {
      dispatch(getTopPosts(1));
    } catch (error) {}
  };

  const getPersonalBI = () => {
    setInfoPopUp(false);
    setTimeout(() => {
      try {
        window.parent.postMessage("3PI", "*");
      } catch (error) {
        console.log("3PI", error);
      }
    }, 100);
  };

  // console.log(topPosts);
  // window.parent.GetPersonalBasicInfo("B0000009-APP001", "めぶくコミュニティ");
  // eslint-disable-next-line

  const updateUser = async (body: any) => {
    try {
      await authService.updateAppUserNameOnly(body);
      dispatch(loginWithToken(user?.auth_token));
    } catch (error) {}
  };

  useEffect(() => {
    function handleMessage(event: any) {
      try {
        setInfoPopUp(false);
      } catch (error) {}

      try {
        // Log and handle the event to check the source
        if (event.source === window.parent) {
          // Set message for debugging/logging purposes

          // Check if the incoming data is already an object or needs parsing
          let data =
            typeof event.data === "string"
              ? JSON.parse(event.data)
              : event.data;

          // Check if the message is from the correct source and contains valid data
          if (data?.name !== "") {
            // Prepare the body with user data
            const result = data;
            let body = {
              name: result?.name,
            };

            // Update user information
            try {
              if (body?.name) {
                // Check if body contains a valid name

                updateUser(body);
              }
            } catch (error) {
              console.error("Error updating user name:", error);
            }
          } else {
            // Handle when data is not correct
          }
        } else {
          // setMessage("Not from parent.");
        }
      } catch (error) {
        // Catch and log errors
        // setMessage("Error occurred while processing the response.");
        // setMessage2("Catch block: " + JSON.stringify(error));
      }
    }

    // Add event listener to receive messages from iframe
    window.addEventListener("message", handleMessage);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line
  }, []);

  // const handleSearch = (searchTerm: any) => {
  //   console.log("Search term:", searchTerm);
  //   // Perform your search logic here
  // };

  const handleReport = async (post_id: number, status: any) => {
    try {
      // setLoading(true);
      const response = await appServices.acceptRejectPost(post_id, status);
      console.log(response, "responseeeeeee");
      await dispatch(getTopPosts(1));
      setTimeout(() => {
        dispatch(removeTopPost(post_id));
      }, 1000);
    } catch (error) {
      // setLoading(false);
    }
  };
  const [likeLoading, setLikeLoading] = useState(false);
  const like = async (item: any, action: string) => {
    setLikeLoading(true);
    try {
      await appServices.likeUnlike(item.id, action);

      try {
        dispatch(getTopPosts(1));

        setLikeLoading(false);
      } catch (error) {}
    } catch (error) {
      setLikeLoading(false);
    } finally {
    }

    // console.log('like')
  };
  const handleThumbUp = async (item: any) => {
    setLikeLoading(true);

    try {
      if (item?.liked_by_me) {
        await like(item, "delete_like");
        // setLikeCount(likeCount - 1); // Decrease the like count
      } else {
        await like(item, "like");
        // setLikeCount(likeCount + 1); // Increase the like count
      }
    } catch (error) {
      console.error("Error while handling thumbs up:", error);
    } finally {
      setLikeLoading(false);
    }
    // console.log(likeCount,'likeCounttttttttttttt')
  };

  const Posts = useMemo(() => {
    return (
      <VerticalListP2
        handleReport={handleReport}
        page={"1"}
        order={"DSC"}
        items={topPosts}
        showRanking
        onLike={handleThumbUp}
        onUnLike={handleThumbUp}
        likeLoading={likeLoading}
      />
    );
    // eslint-disable-next-line
  }, [topPosts]);

  return (
    <div style={{ marginTop: 80, overflow: "scroll" }}>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <ListItemButton onClick={() => navigate(-1)} dense>
          <Typography color="text.primary">{T("Home")}</Typography>
        </ListItemButton>

        <Typography color="text.primary">{T("see_more")}</Typography>
      </Breadcrumbs> */}

      <div onClick={() => navigate("/search")} style={{ margin: "20px" }}>
        <SearchBar reSearch={false} onResults={() => {}} />
      </div>
      <div
        style={{
          // alignItems: "center",
          // justifyContent: "center",
          paddingTop: 16,
          display: "flex",
        }}
      >
        <LoadingModal
          visible={isLoadingTopPosts}
          handleClose={() => console.log("onclose")}
        />
        {/* <img height={40} src={VoteIcon} alt="" /> */}

        <RankingHeader />
      </div>

      <PersonalInfoPopup
        open={infoPopUp}
        onClose={() => {
          setInfoPopUp(false);
        }}
        onNext={() => {
          getPersonalBI();
        }}
      />

      {Posts}
      {/* <SeeMoreList
        onClickItem={(item) => navigate("/detailsview", { state: item })}
        items={topPosts || []}
      /> */}
    </div>
  );
}
