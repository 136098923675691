import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "../../services/app-services/auth-service";

const initialState: any = {
  isLoading: false,
  isSuccess: false,
  loading: false,
  isError: false,
  message: "",
  user: {},
};

export const registerAppUser = createAsyncThunk<any, any>(
  "user/register",
  async (body, thunkAPI) => {
    try {
      return await authService.registerAppUser(body);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loginWithToken = createAsyncThunk<any, any>(
  "user/token",
  async (token, thunkAPI) => {
    try {
      return await authService.loginWithToken(token);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loginNavigator = createAsyncThunk<any, any>(
  "user/loginNavigator",
  async (body, thunkAPI) => {
    try {
      return await authService.loginNavigator(body);
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// ...action.payload?.data?.user,
export const userAuthReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.user = {};
    },
    resetMessage: (state) => {
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAppUser.pending, (state) => {
        state.isLoading = true;
        state.user = {};
      })
      .addCase(registerAppUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.user = {
          ...action.payload?.data?.user,
          auth_token: action.payload.headers["session_id"],
        };
      })
      .addCase(registerAppUser.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        state.user = {};
      })
      ////nav///dumy...///
      .addCase(loginNavigator.pending, (state) => {
        state.isLoading = true;
        state.user = {};
      })
      .addCase(loginNavigator.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.user = {
          ...action.payload?.data?.user,
          nav: "1",
          auth_token: action?.payload?.headers["session_id"]
            ? action?.payload?.headers["session_id"]
            : action?.payload?.headers["auth_token"],
        };
        state.message = "";
      })
      .addCase(loginNavigator.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        state.user = {};
        state.message = action.payload;
      })
      ////nav///dumy...///end ..../

      .addCase(loginWithToken.pending, (state) => {
        state.loading = true;
        state.user = {};
      })
      .addCase(loginWithToken.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = action.payload.success;
        state.user = {
          ...action.payload?.data?.user,
          auth_token: action?.payload?.config?.headers?.session_id,
        };
      })
      .addCase(loginWithToken.rejected, (state: any, action) => {
        state.message = action.payload;
        state.loading = false;
        state.isError = true;
      });
  },
});

export const { reset, resetMessage } = userAuthReducer.actions;

export default userAuthReducer.reducer;
