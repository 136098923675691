import { Box } from "@mui/material";

interface Props {
  color: string;
}
function Separator({ color }: Props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "2px",
        backgroundColor: color,
      }}
    />
  );
}

export default Separator;
