import React from "react";
import { Box, Typography } from "@mui/material";

const RankingHeader = () => {
  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        borderBottomWidth: 3,
        borderBottomStyle: "solid",
        margin: 8,
      }}
      justifyContent="space-between"
    >
      <Box style={{ width: "100%" }} flexDirection={"row"} display="flex">
        <img
          alt=""
          style={{ height: 76 }}
          src={require("../../assets/images/trophy.png")}
        />

        <div style={{ width: "100%" }}>
          <Typography variant="h4" component="div" fontWeight="bold">
            &nbsp;RANKING!
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ fontWeight: "600" }}
              variant="subtitle1"
              component="div"
            >
              &nbsp; ランキング
            </Typography>
            {/* <Typography
              style={{ fontWeight: "580" }}
              variant="subtitle2"
              component="div"
            >
              毎日12時更新！
            </Typography> */}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default RankingHeader;
