import axiosService from "./axios-services";



const setAuthorizationToken = (token: string | undefined) => {
	//  console.log('Set Authorization token', token);
   if (token) {
	    axiosService.defaults.headers.common.session_id = `${token}`;
	   axiosService.defaults.headers.common["Content-Type"]='application/json'
	   axiosService.defaults.headers.common.auth_token = ``
   } else {
	   delete axiosService.defaults.headers.common.Authorization;
	   delete axiosService.defaults.headers.common.session_id;
	   axiosService.defaults.headers.common.auth_token = 'fU5DZc1HCdlBzfJZ5k9ECR7tU8a11685OWZ0J9T1QqWgqWvdbR538aOjGfP4SMpG'
   }
};
export default setAuthorizationToken;

