import * as CryptoJS from "crypto-js";
var secretKey =
  "d23bf37d1d9291f4ebbf1ed0a2d4abf9951f873bd9073b444b37b9e4939298a4";
const isAuthorized = () => {
  const token = sessionStorage.getItem("communityToken");
  if (token?.length) {
    // console.log("here", token);
    const bytes = CryptoJS.AES.decrypt(token, secretKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } else {
    return false;
  }
};
const loginUser = (user: any) => {
  const key = CryptoJS.AES.encrypt(
    JSON.stringify(user.auth_token),
    secretKey
  ).toString();
  const USER = CryptoJS.AES.encrypt(JSON.stringify(user), secretKey).toString();
  sessionStorage.setItem("communityToken", key);
  sessionStorage.setItem("communityUser", USER);
};
const getUser = () => {
  const user = sessionStorage.getItem("communityUser");
  if (user?.length) {
    const bytes = CryptoJS.AES.decrypt(user, secretKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } else {
    return false;
  }
};
const logoutUser = () => {
  sessionStorage.setItem("communityToken", "");
};
const userAuth = {
  isAuthorized,
  loginUser,
  logoutUser,
  getUser,
};
export default userAuth;
